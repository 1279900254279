import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import io from 'socket.io-client'

import './Chat.scss'

// import RoomChat from './LEGACY-RoomChat';
import RoomChat from './RoomChat'
// import DirectChat from './LEGACY-DirectChat';
import DirectChat from './DirectChat'

// import NewChat from './LEGACY-NewChat';
import NewChat from './NewChat'

import { presence } from '../../redux/actions/user'

import ChatSidecar from './ChatSidecar'

const BACKEND = process.env.REACT_APP_CHAT

class Chat extends Component {
  state = {
    context: this.props?.context || 'general',
    selectedTab: 0,
    presence: false,
    ENDPOINT: BACKEND,
    totalUnreadConversation: 0,
  }

  socket = io(this.state.ENDPOINT,{
    // transports: ['polling']
    transports: ['websocket']
  })


  componentDidMount() {}

  getTotalUnreadConversations(id, ENDPOINT) {
    console.log('getTotalUnreadConversations ' + id + ENDPOINT)
    // Retrieving total number of unread conversations
    axios
      .get(`${ENDPOINT}UnreadMessagesCount/${id}`)
      .then((res) => {
        console.log(`${ENDPOINT}UnreadMessagesCount/${id}`)
        console.log(res.data.response)
        this.setState({ totalUnreadConversation: res.data.response })
      })
      .catch((e) => {
        console.log(e)
        // console.log(e.response.data)
      })

    // join a room to get live count update
    this.socket.emit('unreadConversationsCountJoin', { from: id }, (error) => {
      if (error) {
        alert(error)
      }
    })

    // get live count update from the room whenever any message sent to me
    this.socket.on('unreadConversationsCount', (isSentToMe) => {
      console.log(isSentToMe)
      if (isSentToMe.to === id) {
        // Retrieving total number of unread conversations
        axios
          .get(`${ENDPOINT}UnreadMessagesCount/${id}`)
          .then((res) => {
            console.log(`${ENDPOINT}UnreadMessagesCount/${id}`)
            console.log(res.data.response)
            this.setState({ totalUnreadConversation: res.data.response })
          })
          .catch((e) => {
            console.log(e)
            // console.log(e.response.data)
          })
      }
    })
  }

  render() {
    if (this.props.user && !this.state.presence) {
      this.setState({ presence: true })
      this.props.user && this.props.presence(this.state.context)
      console.log(this.props.user)
      this.getTotalUnreadConversations(this.props.user.id, this.state.ENDPOINT)
    }

    console.log('online', this.props.online)

    const online = this.props.online.filter(
      (user) => user.context == this.state.context
    )

    return (
      <div id="chat" className={`${this.props.conference && 'conference'}`}>
        <ChatSidecar context={this.state.context} />

        <div className="tabs">
          <div className="tabs-wrapper">
            {['ROOM CHAT', 'DIRECT MESSAGE', 'INBOX'].map((tab, key) => {
              return (
                <div
                  key={key}
                  className={`tab body3 ${
                    this.state.selectedTab == key && 'active'
                  }`}
                  onClick={() => {
                    this.setState({ selectedTab: key })
                  }}
                  // style={{ display: 'flex' }}
                >
                  {tab}
                  {tab === 'INBOX' &&
                    this.state.totalUnreadConversation > 0 && (
                      <span class="new-msg-label--secondary">
                        {this.state.totalUnreadConversation}
                      </span>
                    )}
                </div>
              )
            })}
            <div className="users body3">
            <img src={'./img/users.svg'}></img>
              {online.length}
            </div>
          </div>
        </div>
        {this.state.selectedTab == 0 && (
          <RoomChat context={this.state.context} />
        )}
        {this.state.selectedTab == 1 && (
          <DirectChat context={this.state.context} />
        )}
        {this.state.selectedTab == 2 && (
          <NewChat
            updateTotalUnread={this.getTotalUnreadConversations.bind(this)}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user?.userData,
  online: state.user?.online,
})

const mapDispatchToProps = (dispatch) => {
  return {
    presence: (user, context) => dispatch(presence(user, context)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
