import React from 'react';
import FeatureVideosCard from '../preevent/sections/featureVideos/featureVideosCard/FeatureVideosCard';
import videoData from '../preevent/sections/featureVideos/FeatureVideos.json';
import './OnDemandVideos.scss';

const OnDemandVideosRight = ({ type }) => {
  return (
    <>
      {videoData
        .filter((el) => el.type === type)
        .map((el) => (
          <FeatureVideosCard key={videoData.id} item={el} />
        ))}
    </>
  );
};

export default OnDemandVideosRight;
