
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { useJitsi } from './Jutsu'
import { Redirect } from 'react-router'

import { Modal } from 'antd';

const JITSI_DOMAIN = process.env.REACT_APP_CONFERENCE || 'jitsi.virtualhabitathouseparty.org'

// let Loading = () => {
//     return (
//         <p>Loading ...</p>
//     )
// }

const Jitsi = (props) => {

    // const [loading, setLoading] = useState(true)
    const [kick, setKick] = useState(false)

    const containerStyle = {
        // position: 'absolute',
        // top: 70,
        // bottom: 0,
        // left: 0,
        // right: 0,
        width: '100%',
        height: '100%'
    }

    const jitsiContainerStyle = {
        // opacity: loading ? 0.5 : 1,
        width: '100%',
        height: '100%'
    }

    const user = props.user.userData
    const domain = JITSI_DOMAIN
    const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqaXRzaS52aXJ0dWFsaGFiaXRhdGhvdXNlcGFydHkub3JnIiwiYXVkIjoibXlfaml0c2lfYXBwX2lkIiwiaXNzIjoibXlfaml0c2lfYXBwX2lkIiwicm9vbSI6IioifQ.qozcWIuhdEGeeqQq3YPv4SoIa1EGVpu7q_m6FHieEB4'
    const parentNode = 'room'
    let options = {
        roomName: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXasasdasddVCJ9-${props.room}`,
        parentNode: parentNode,
        configOverwrite: { 
            disableDeepLinking: true,
            requireDisplayName: false,
            enableWelcomePage: true,
            prejoinPageEnabled: false,
            // startWithAudioMuted: false,
            // startWithVideoMuted: false,
        },
        userInfo: {
            "displayName": `${user?.profile?.firstName} ${user?.profile?.lastName}`.trim(),
            "email": user.email,
            "avatarUrl": user.getAvatarUrlForWatchParty()
        },
        interfaceConfigOverwrite: { 

            APP_NAME: 'Conference',

            DEFAULT_REMOTE_DISPLAY_NAME: 'Attendee',

            filmStripOnly: props?.filmStripOnly || false,
            SHOW_POWERED_BY: false,
            SHOW_JITSI_WATERMARK: false,
            DISABLE_VIDEO_BACKGROUND: true,
            SHOW_WATERMARK_FOR_GUESTS: false,

            SHOW_CHROME_EXTENSION_BANNER: false,

            SHOW_DEEP_LINKING_IMAGE: false,
            SHOW_PROMOTIONAL_CLOSE_PAGE: false,

            CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 0,
            CONNECTION_INDICATOR_DISABLED: true,
            DISABLE_RINGING: true,
            DISABLE_TRANSCRIPTION_SUBTITLES: true,
            DISPLAY_WELCOME_PAGE_CONTENT: false,
            HIDE_INVITE_MORE_HEADER: true,

            HIDE_KICK_BUTTON_FOR_GUESTS: true,
            VIDEO_QUALITY_LABEL_DISABLED: true,

            DEFAULT_BACKGROUND: 'black',

            TILE_VIEW_MAX_COLUMNS: 1,

            VERTICAL_FILMSTRIP: false,

            TOOLBAR_BUTTONS: [
                // 'microphone',
                // // 'camera',
                // // 'closedcaptions',
                // // 'desktop',
                // // 'fullscreen',
                // 'fodeviceselection',
                // 'hangup',
                // 'profile',
                // // 'info',
                // 'chat',
                // // 'recording',
                // // 'livestreaming',
                // // 'etherpad',
                // // 'sharedvideo',
                // 'settings',
                // // 'raisehand',
                // // 'videoquality',
                // // 'filmstrip',
                // // 'invite',
                // // 'feedback',
                // // 'stats',
                // // 'shortcuts',
                // 'tileview',
                // // 'videobackgroundblur',
                // // 'download',
                // // 'help',
                // // 'mute-everyone',
                // // 'e2ee'
            ]
        },
        //including the jwt grants the user moderator rights
        //only do so if they are an invited guest of this room's sponsor
        // jwt: jwt,
    };

    let vars = {}
    
    let jitsi = useJitsi(options, domain)

    useEffect(() => {
        if (jitsi) {
            jitsi.addEventListener('tileViewChanged', e => {
                e.enabled && jitsi.executeCommand('toggleTileView');
            })
            jitsi.addEventListener('filmstripDisplayChanged', e => {
                !e.visible && jitsi.executeCommand('toggleFilmStrip');
            })
        }
        return () => jitsi && jitsi.dispose();
    }, [jitsi, props.room])

    useEffect(() => {
        jitsi && jitsi.addEventListener('videoConferenceJoined', () => {
            props.joined()
        })
    }, [jitsi, props.joined]);

    useEffect(() => {
        jitsi && jitsi.addEventListener('videoConferenceJoined', () => {
            const vhhpUser = props.user.userData
            if (vhhpUser) {
                jitsi.executeCommand('displayName', `${vhhpUser.profile.firstName} ${vhhpUser.profile.lastName}`);
                jitsi.executeCommand('email', vhhpUser.email);
                //jitsi.executeCommand('avatarUrl', vhhpUser.avatars.length ? vhhpUser.avatars[0].avatarUrl : '');
                jitsi.executeCommand('avatarUrl', vhhpUser.getAvatarUrlForWatchParty());

            }
        })
    }, [jitsi, props.user.userData]);

    useEffect(() => {
        jitsi && jitsi.executeCommand('toggleVideo')
        jitsi && jitsi.addEventListener('videoMuteStatusChanged', muted => props.video == muted && jitsi.executeCommand('toggleVideo'))
        // jitsi && jitsi.addEventListener('videoMuteStatusChanged', props.videoMuteStatusChanged)
    }, [jitsi, props.video]);

    useEffect(() => {
        jitsi && jitsi.executeCommand('toggleAudio')
        jitsi && jitsi.addEventListener('audioMuteStatusChanged', muted => props.audio == muted && jitsi.executeCommand('toggleAudio'))
        // jitsi && jitsi.addEventListener('audioMuteStatusChanged', props.audioMuteStatusChanged)
    }, [jitsi, props.audio]);
    
    useEffect(() => {
        jitsi && jitsi.addEventListener('participantKickedOut', event => {
            console.log(event)
            if (event.kicked.local) {
                setKick(true)
                  Modal.warning({
                    title: 'You have been asked to leave the room',
                    content: 'Please make sure you are following the guidelines. If you have any questions, please reach out to our customer service.',
                  });
            }
        })
    }, [jitsi]);
    
    if (kick) return <Redirect to='/#rooms'/>
  
    return (
        <div style={ containerStyle }>
          {/* {loading && <Loading />} */}
          <div
            id={ parentNode }
            style={ jitsiContainerStyle }
          />
        </div>
      )
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jitsi))