
const { BlobServiceClient } = require("@azure/storage-blob")


class AzureFileUploader{
    async uploadFile(file,userid=new Date().getTime()){
        const STORAGE_ACCOUNT_NAME = process.env.REACT_APP_BLOB_ACCOUNT;
        const CONTAINER_NAME = process.env.REACT_APP_BLOB_CONTAINER;
        const SAS_TOKEN = process.env.REACT_APP_BLOB_SAS;
        const sasURL = `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${SAS_TOKEN}`
        console.log("sas URL",sasURL)

        const blobServiceClient = new BlobServiceClient(sasURL)
        const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)

        const filename = userid+"_avatar_"
        const ext = file.name.substring(file.name.lastIndexOf('.'))
        const blobName = filename+new Date().getTime()+ext
        const blockBlobClient = containerClient.getBlockBlobClient(blobName)
        const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file)
        console.log(`Upload block blob ${userid} successfully`, uploadBlobResponse.clientRequestId)
        return process.env.REACT_APP_BLOB_URL+blobName
    }

}

export default new AzureFileUploader()
