import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import * as t from './redux/types';

const CONTEXT_PREFIX = process.env.REACT_APP_CHAT_CONTEXT_PREFIX;
const ENDPOINT = process.env.REACT_APP_DASHBOARD
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY || "04e3f7b4fcc8c60e7901"

const Pusher = ({ context, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener(
      'beforeunload',
      () => window.pusher && window.pusher.disconnect()
    );

    return () => {
      window.pusher && window.pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    if (user && context) {
      // window.Pusher.logToConsole = true;

      const name = `${user?.profile?.firstName} ${user?.profile?.lastName}`.trim();

      if (!window?.pusher?.disconnect()) {
        console.log('Window pusher', window.pusher);
        window.pusher = new window.Pusher(`${PUSHER_KEY}`, {
          cluster: 'us2',
          forceTLS: true,
          authEndpoint: `${ENDPOINT}api/v1/pusher/token/${
            user.id
          }/${context}/${CONTEXT_PREFIX}/${encodeURI(name)}`,
        });

        const channel = window.pusher.subscribe(`presence-vhhp-v23`);

        channel.bind('pusher:subscription_succeeded', () => {
          let members = channel?.members?.members || {};
          console.log('Members: ', members);
          dispatch({
            type: t.ADD_ONLINE_USERS,
            payload: Object.keys(members).map((key) => members[key]),
          });
        });

        channel.bind('pusher:member_added', (member) => {
          console.log('ADD Members: ', member);
          dispatch({
            type: t.ADD_ONLINE_USER,
            payload: member?.info,
          });
        });

        channel.bind('pusher:member_removed', (member) => {
          console.log('REMOVE Members: ', member);
          dispatch({
            type: t.REMOVE_ONLINE_USER,
            payload: member?.id,
          });
        });
      }
    }
  }, [context, user]);

  return <></>;
};

const mapStateToProps = (state) => ({
  user: state.user?.userData,
  context: state.user.context,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pusher);
