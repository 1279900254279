import React from 'react';
import './FeatureVideosCard.scss';
import { Link } from 'react-router-dom';

const FeatureVideosCard = ({ item }) => {
  return (
    <div className='feature-video-card__card'>
      <Link to={'/ondemandvideos/' + item.id}>
        <div className='feature-video-card__image'>
          <div className='feature-video-card__thumbnail'>
            <img src={`/images/thumbnails/${item.thumbnail}`} alt='' />
          </div>
          <div className='feature-video-card__play-btn'>
            <img src={'/images/play-btn.svg'} alt='' />
          </div>
        </div>
      </Link>

      <div className='feature-video-card__content'>
        <div className='content-wrapper'>
          <div className='feature-video-card__title h3 gray-1'>
            {item.title && item.title}
          </div>
          <div className='feature-video-card__title-2 body2 gray-1'>
            {item.speakers && item.speakers.map((v) => v.name).join(', ')}
          </div>
          <div className='feature-video-card__text body3 gray-1'>
            {item.text && item.text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureVideosCard;
