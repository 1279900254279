import React, { useState } from 'react'
import './SponsorsSection.scss'
import SponsorsSectionLeft from './SponsorsSectionLeft'
import SponsorsSectionRight from './SponsorsSectionRight'

const SponsorSection = () => {
  const [sponsorType, setSponsorType] = useState('rtr')

  return (
    <>
      <div className="sponsors-section" id="sponsors">
        <SponsorsSectionLeft
          sponsorType={sponsorType}
          setSponsorType={setSponsorType}
        />
        <SponsorsSectionRight sponsorType={sponsorType} />
      </div>
    </>
  )
}

export default SponsorSection
