import React, { Fragment } from 'react';
import './VideoInfo.scss';
import { connect } from 'react-redux';
import { now } from 'moment';
import SpeakerContainer from './SpeakerContainer';

const VideoInfo = ({
  showUpcoming,
  theme,
  background,
  view,
  nowPlaying,
  upNext,
}) => {
  return (
    <div className='video-info'>
      <div
        className={`video-info__vid-desc ${
          theme === 'dark' ? 'dark' : theme === 'light' ? 'light' : ''
        } ${background ? 'background' : ''}`}
      >
        <div
          className={`video-info__vid-desc-content ${
            showUpcoming && view === 'sponsor_r1' ? 'underline' : ''
          }`}
        >
          {view === 'sponsor_r1' && (
            <Fragment>
              <div className='video-info__desc '>
                <div className='video-info__header label--primary white'>
                  Now Playing
                </div>
                <div className='video-info__title white h1'>
                  {nowPlaying && nowPlaying.title}
                </div>
                <div className='video-info__text white body2'>
                  {nowPlaying && nowPlaying.description}
                </div>
              </div>
            </Fragment>
          )}

          {view === 'sponsor_r2' && (
            <Fragment>
              <div className='video-info__desc '>
                <div className='video-info__header label--primary white'>
                  Now Playing
                </div>
                <div className='video-info__title white h1'>
                  Virtual Habitat House Party Livestream
                </div>
                <div className='video-info__text white body2'>
                  Welcome to the finale of the first-ever Virtual Habitat House
                  Party! We’re celebrating New York’s sustainable future. Stay
                  tuned and be the first to hear an exciting announcement that
                  will change Habitat NYC forever.
                </div>
              </div>
            </Fragment>
          )}

          {view === 'sponsor_r1' && (
            <div className='video-info__side-desc'>
              <div className='label--primary white'>
                {nowPlaying && nowPlaying.guestSpeakers.length > 0
                  ? 'Speaker'
                  : ''}
              </div>
              <div className='speaker-container'>
                {nowPlaying &&
                  nowPlaying.guestSpeakers &&
                  nowPlaying.guestSpeakers.map((speaker) => (
                    <SpeakerContainer speaker={speaker} key={speaker.vhhpEventSpeakerID} />
                  ))}
              </div>
            </div>
          )}
        </div>

        {showUpcoming && view === 'sponsor_r1' && (
          <div className='video-info__desc video-info__upcoming'>
            <div className='video-info__header label--primary white'>
              Coming up next
            </div>
            <div className='video-info__title white h1'>
              {upNext && upNext.title}
            </div>
            <div className='video-info__text white body3'>
              {upNext &&
                upNext.guestSpeakers.map((item) => item.name).join(', ')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view: state.interactions.view,
  agendas: state.dashboard.agendas,
  mondayData: state.dashboard.mondayData,
  tuesdayData: state.dashboard.tuesdayData,
  nowPlaying: state.dashboard.nowPlaying,
  upNext: state.dashboard.upNext,
});

export default connect(mapStateToProps)(VideoInfo);
