import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import { connect } from 'react-redux'
import axios from 'axios'
import './NewChat.scss'

import Guard from './Guard'
const ENDPOINT = process.env.REACT_APP_CHAT || 'http://localhost:3000/'

let socket

export const ChatUI = ({ user, self, ...props }) => {
  console.log(user)
  console.log(self)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [messagesEnd, setMessagesEnd] = useState(null)
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [name, setName] = useState('')

  const scrollToBottom = () => {
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    if (messagesEnd) messagesEnd.scrollTop = messagesEnd?.scrollHeight
  }

  useEffect(() => {
    // ! Don't use any of these state inside this useeffect
    setFrom(self.id)
    setTo(user.vhhpUserID)
    setName(`${self?.profile?.firstName} ${self?.profile?.lastName}`)
    // ! Don't use any of these state inside this useeffect

    socket = io(ENDPOINT,{
      transports: ['websocket']
      // transports: ['polling']
    })


    // Create a unique private room for two individuals
    socket.emit(
      'individualchat',
      { from: self.id, to: user.vhhpUserID },
      (error) => {
        if (error) {
          alert(error)
        }
      }
    )

    // get live messages
    socket.on('getPrivateMessage', (message) => {
      console.log(message)
      // Making seen all the messages from the sending user
      axios
        .get(`${ENDPOINT}seenMessage/${user.vhhpUserID}/${self.id}`)
        .then((res) => {
          console.log(res.data.response)
        })
        .catch((e) => {
          console.log(e.response)
        })
      setMessages((messages) => [...messages, message])
      scrollToBottom()
    })

    // Retrieving all messages from mongoDB
    axios
      .get(`${ENDPOINT}privateChats/${self.id}/${user.vhhpUserID}`)
      .then((res) => {
        // console.log(res.data.response)
        setMessages((messages) => [...messages, ...res.data.response])
      })
      .catch((e) => {
        // console.log(e.response.data)
      })

    // make seen all message from the user
    axios
      .get(`${ENDPOINT}seenMessage/${user.vhhpUserID}/${self.id}`)
      .then((res) => {
        console.log(res.data.response)
        // Update tabbar unseen message after 'Inbox'
        props.updateTotalUnread(self.id, ENDPOINT)
      })
      .catch((e) => {
        console.log(e.response)
      })

    scrollToBottom()
    return () => socket.disconnect()
  }, [user, self])

  const sendMessage = (event) => {
    event.preventDefault()
    console.log(message)
    const input = Guard(message)
    if (input) {
      Guard(message)
      socket.emit(
        'sendIndividualMessage',
        {
          from,
          to,
          message: input,
          name,
          // avatar: user.profile.avatarUrl // user another way
        },
        () => setMessage('')
      )
    }
  }

  return (
    <>
      <div
        className="chatbox scroll"
        ref={(el) => {
          setMessagesEnd(el)
        }}
      >
        <div className="new-chat">
          <div className="chat-ui">
            <div className="chat-ui__header">
              <div className="chat-ui__back body3" onClick={props.back}>
                <img src="/images/button_back.png" alt="" />
                Back
              </div>
              <div className="user-name body2">
                {
                  // user.getDisplayName()
                  `${user?.profile?.firstName} ${user?.profile?.lastName}`.trim()
                }
              </div>
            </div>
            <div
              className="chat-ui__messages scroll"
              ref={(el) => {
                setMessagesEnd(el)
              }}
            >
              {messages.map((chat, idx) => {
                return (
                  <div
                    key={idx}
                    className={`chat-ui__messages__message body3 ${
                      chat.from === self.id && 'self'
                    }`}
                  >
                    {chat.message}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="typer">
        <form
          className="messageForm"
          //  onSubmit={this.handleNewMessage}
        >
          <div className="input-container">
            <input
              className="body2"
              //   ref={(node) => (this.input = node)}
              placeholder="Type a message..."
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
              onKeyPress={(event) =>
                event.key === 'Enter' ? sendMessage(event) : null
              }
            />
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  self: state.user?.userData,
  //   user: state.user?.userData,
  //   users: state.user?.users,
  //   online: state.user?.online,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChatUI)
