import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import './NewChat.scss'
import io from 'socket.io-client'

import SearchChat from './SearchChat'
import ChatUI from './ChatUI'

const ENDPOINT = process.env.REACT_APP_CHAT || 'http://localhost:3000/'

let socket

export const DirectChat = ({ user, users, online, self, ...props }) => {
  const [messagesEnd, setMessagesEnd] = useState(null)
  const [query, setQuery] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [u, setU] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const context = props.context
  const [conversations, setConversations] = useState([])

  const handleSearch = (e) => {
    const query = e?.target?.value
    if (user) {
      const u = users.filter((user) =>
        `${user?.profile?.firstName} ${user?.profile?.lastName}`
          .toLowerCase()
          .includes(query.toLowerCase())
      )
      setU(u)
      setQuery(query)
    }
  }

  const getTimeAMPMFormat = (date) => {
    console.log(typeof date)
    const dateObj = new Date(date)
    console.log(typeof dateObj)
    if (dateObj) {
      let hours = dateObj.getHours()
      let minutes = dateObj.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours
      // appending zero in the start if hours less than 10
      minutes = minutes < 10 ? '0' + minutes : minutes
      console.log(hours + ':' + minutes + ' ' + ampm)
      return hours + ':' + minutes + ' ' + ampm
    }
    return ''
  }

  const getUserName = (id) => {
    const nameArray = users.filter((el) => {
      if (el.vhhpUserID === id) {
        return `${el?.profile?.firstName} ${el?.profile?.lastName}`
      }
    })
    const name = `${nameArray[0]?.profile?.firstName} ${nameArray[0]?.profile?.lastName}`

    console.log(name)
    return name
  }

  const getAvatar = (id) => {
    const nameArray = users.filter((el) => {
      if (el.vhhpUserID === id) {
        return el
      }
    })
    const imageErrorSrc = '/avatar/default/default-05.png'
    const avatar = nameArray[0]?.profile?.avatarUrl || imageErrorSrc
    console.log(avatar)
    return avatar
  }

  const getSelectedUser = (id) => {
    const selectedUser = users.filter((el) => {
      if (el.vhhpUserID === id) {
        return el
      }
    })
    console.log(selectedUser[0])
    return selectedUser[0]
  }

  useEffect(() => {
    setLoaded(true)
    // Retrieving all conversations list with last message from mongodb
    axios
      .get(`${ENDPOINT}conversationList/${self.id}`)
      .then((res) => {
        console.log(`${ENDPOINT}conversationList/${self.id}`)
        console.log(res.data.response)
        setConversations(res.data.response)
      })
      .catch((e) => {
        console.log(e)
        // console.log(e.response.data)
      })

    socket = io(ENDPOINT,{
      // transports: ['polling']
      transports: ['websocket']
    })
  
    console.log(socket)

    socket.emit('updateConversationsJoin', { from: self.id }, (error) => {
      if (error) {
        alert(error)
      }
    })

    socket.on('updateConversations', (isSentToMe) => {
      console.log(isSentToMe)
      if (isSentToMe.to === self.id) {
        // Retrieving all conversations list with last message from mongodb
        axios
          .get(`${ENDPOINT}conversationList/${self.id}`)
          .then((res) => {
            console.log(res.data.response)
            setConversations(res.data.response)
          })
          .catch((e) => {
            console.log(e)
            console.log(e.response)
          })
      }
    })

    // TODO remove
    if (user) {
      setLoaded(true)
      const u = users
      setU(u)
    }

    // close the connection when the component unmounts.
    return () => socket.disconnect()
  }, [user, users, selectedUser])

  return (
    <>
      <div
        className="chatbox"
        ref={(el) => {
          setMessagesEnd(el)
        }}
      >
        <div
          className="new-chat-msgs"
          ref={(el) => {
            setMessagesEnd(el)
          }}
        >
          {loaded && (
            <div className="users-chat-wrapper">
              {!selectedUser && (
                <div className="chatbox scroll">
                  <div className="direct-msg-title">
                    <div className="body1">All Participants</div>
                  </div>
                  <SearchChat onChange={handleSearch} />
                  {conversations.map((user) => {
                    const imageErrorSrc = '/avatar/default/default-05.png'
                    const img = user?.profile?.avatarUrl || imageErrorSrc
                    const name = user?.profile?.firstName
                      ? `${user?.profile?.firstName} ${user?.profile?.lastName}`
                      : user?.name
                    return (
                      <div
                        className="new-message"
                        onClick={() => {
                          setSelectedUser(
                            getSelectedUser(
                              self.id === user.from ? user.to : user.from
                            )
                          ) // TODO FIX bug here
                        }}
                      >
                        <div className="thumbnail pointer">
                          <img
                            src={getAvatar(
                              self.id === user.from ? user.to : user.from
                            )}
                            alt=""
                            onError={(e) => (
                              (e.target.onerror = null),
                              (e.target.src = imageErrorSrc)
                            )}
                          ></img>
                        </div>
                        <div className="content pointer">
                          <div className="name label--primary">
                            {getUserName(
                              self.id === user.from ? user.to : user.from
                            )}
                          </div>
                          <div className="message body2">{user.message}</div>
                        </div>
                        <div className="time label--secondary">
                          {
                          getTimeAMPMFormat(user.date)
                         }

                          {self.id !== user.from && user.unread > 0 && (
                            <div className="new-msg label--secondary">
                              {user.unread}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              {selectedUser && (
                <div className="user-chat">
                  <ChatUI
                    user={selectedUser}
                    back={() => {
                      setSelectedUser(null)
                    }}
                    updateTotalUnread={
                      props.updateTotalUnread
                    }
                  />
                </div>
              )}
            </div>
          )}

          {!loaded && (
            <div className="chat-filler label--primary">Connecting...</div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user?.userData,
  users: state.user?.users,
  online: state.user?.online,
  self: state.user?.userData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DirectChat)
