import { Modal } from 'antd';
import badWords from './badWords';
var Filter = require('bad-words'),
    filter = new Filter();
    filter.addWords(...badWords);

const Guard = (input) => {
    
    let cleanedInput = ""
    try{
        cleanedInput = filter.clean(input)  //try catch in case input has no detectable words, just return it. If this doesnt exist it will throw an error
    }
    catch{
        console.log("Error parsing safeguard bad words, giving users a reasonable doubt and letting it pass")
        return input
    }

    if (input == cleanedInput) {
        return input
    } else {
        Modal.warning({
            title: 'Failed to send message',
            content: 'Please make your language is clean.',
        });
        return ""
    }
}

export default Guard
