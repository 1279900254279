import React from 'react';
import VideoInfo from '../../../Elements/VideoInfo';
import AgendaInfo from './AgendaInfo';
import './InfoSection.scss';

const InfoSection = () => {
  return null
  return (
    <div className='info-section'>
      <VideoInfo showUpcoming={true} theme={''} background={false} />
      {/* <AgendaInfo /> */}
    </div>
  );
};

export default InfoSection;
