import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { EvalSourceMapDevToolPlugin } from 'webpack';
import defaultProfilePic from "../registration/img/Profile-Picture.svg";
import { AvatarSource } from './AvatarField';

const Large = {
    fontSize: "2.25rem",
    letterSpacing: "-0.0025rem",
    height: "100px",
    width: "100px"
};

const Small = {
    fontSize: "1.125rem",
    letterSpacing: "-0.00625rem",
    height: "42px",
    width: "42px"
};

const XSmall = {
    fontSize: "1rem",
    letterSpacing: "normal",
    height: "36px",
    width: "36px"

}

const StyledContainer = styled.div`    
    height: ${props => props.theme.height};
    width: ${props => props.theme.width};
`;

const StyledImg = styled.img`
    height: ${props => props.theme.height};
    width: ${props => props.theme.width};
    text-align: center;
    border-radius: 50%;
    border: 1px solid white;
    vertical-align: middle;
`;

const StyledInitials = styled.div`
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;    
    background-color: #D4F2F3;
    height: ${props => props.theme.height};
    width: ${props => props.theme.width};
    font-size: ${props => props.theme.fontSize};
    line-height: ${props => props.theme.height};        
    letter-spacing: ${props => props.theme.letterSpacing};
    text-align: center;
    border-radius: 50%;
    border: 1px solid white;
    text-decoration: none;
`;

export type AvatarSize = "Large" | "Small" | "XSmall";

interface AvatarProps {
    size: AvatarSize; //100px, 42px, 36px
    source: AvatarSource; // "UserInitials" | string url    
    firstName?: string;
    lastName?: string;
    wrapperClass?: string;
    onClickHandler?: (e: React.MouseEvent) => void;
}


const Avatar = (props: AvatarProps) => {
    let contents: JSX.Element = <></>;
    const initials = (props.firstName?.charAt(0) || "") + (props.lastName?.charAt(0) || "")
    const imgAlt = (props.firstName || props.lastName)
        ? ((props.firstName || "") + " " + (props.lastName || "")).trim()
        : "User's Profile"; 
    const theme = props.size === "Large"
        ? Large
        : props.size === "Small"
            ? Small
            : XSmall;
    if (props.source === "UserInitials" && (props.firstName || props.lastName)) {
        contents = <StyledInitials onClick={props.onClickHandler}>{initials}</StyledInitials>
    } else if (props.source && props.source !== "UserInitials" && props.source.length > 0) {
        contents = <StyledImg src={props.source} alt={imgAlt} onClick={props.onClickHandler} />
    } else {
        contents = <StyledImg src={defaultProfilePic} alt={imgAlt} onClick={props.onClickHandler} />
    }

    return (
        <div className={props.wrapperClass}>
            <ThemeProvider theme={theme}>
                <StyledContainer>
                    {contents}
                </StyledContainer>
            </ThemeProvider>
        </div>
    );
}

export default Avatar;