import './SponsorsSection.css';
import backgroundImg from './img/Sponspor_BG_Web.png';
import sponsorBottomImg from './img/sponsor_mobile.png'
import React, { Component } from 'react';
import VhhpButton from '../../Elements/VhhpButton';

interface SponsorsSectionProps {
}
export default class SponsorsSection extends Component<SponsorsSectionProps> {

    render() {
        return (
            <div className="sponsors-container">
                <div className="sponsors-content">
                    <div className="sponsor-shrink-background">
                    <div className="sponsors-content_header label--primary">Sponsors</div>
                    <div className="sponsors-content_title display">Become a Sponsor</div>
                    </div>

                    <div className="sponsors-content_body body2">Virtual Habitat House Party sponsors not only help to build a healthier New York, but will have their brand seen by thousands of New Yorkers. Learn <a className="sponsor-url-text" href='https://habitatnycwc.org/2021-sponsorships/'
                    ><strong>more here</strong></a> to view our sponsorship brochure.</div>
                    <div className="sponsor-btn-container">
                        <VhhpButton text="Learn More" url="https://habitatnycwc.org/2021-sponsorships/" styleOptions={{ color: "white", background: "transparent", outline: true }}></VhhpButton>
                    </div>
                    <div className="sponsor-shrink-btn-container">
                        <VhhpButton text="Learn More" url="https://habitatnycwc.org/2021-sponsorships/" styleOptions={{ color: "gray", background: "transparent", outline: true }}></VhhpButton>
                    </div>
                </div>
                <div className="sponsors-img-container">
                    {/*<img src={backgroundImg} className="sponsors-img-container_img" alt="" />*/}
                </div>
                <div className="sponsor-bottom-img-container">
                    <img className="sponsor-bottom-img" src={sponsorBottomImg} alt="" />

                </div>
            </div>
        );
    }
}

