import React from 'react'
import './AuctionSection.scss'
import VhhpButton from '../../../Elements/VhhpButton'

const AuctionSection = () => {
  // return {
  //   /* <div className='auction-section__container container--pall'>
  //     <div className='auction-section__content'>
  //       <div className='auction-section__header label--primary gray-1'>
  //         Silent Auction
  //       </div>
  //       <div className='auction-section__title display gray-1'>
  //         #DesignUnites to Support Habitat NYC
  //       </div>
  //       <div className='auction-section__text p body2 gray-1'>
  //         Bid on life changing experiences and one-of-a-kind items donated from
  //         our friends at Hearst Luxury & Design Collection, powered by
  //         CharityBuzz. #DesignUnites auction opens <span> 10.26.20</span>
  //       </div>
  //     </div>
  //   </div> */
  // };

  return (
    <>
      <div className="silent-auction-container">
        <div className="auction-mobile-top-left-arrow"></div>
        <div className="silent-auction-content">
          <div className="silent-auction-content_header label--primary gray-1">
            Silent Auction
          </div>
          <div className="silent-auction-content_title display orange-text">
            Auction Benefiting Habitat NYC and Westchester 
          </div>
          <div className="silent-auction-content_body body2">
          We are delighted to offer this extra opportunity to raise much-needed funds for Habitat NYC and Westchester and the hard-working families we serve. Please join your fellow New Yorkers in bidding generously to help support our cause. Click below to preview and bid on auction items and experiences. 

          </div>
          <a href="https://handbid.app.link/HabitatNYCWC" target="_blank">
            <VhhpButton
              text="View Auction"
              styleOptions={{
                color: 'white',
                background: 'blue',
                outline: 'gray',
              }}
            ></VhhpButton>
          </a>
        </div>
        <div className="auction-mobile-bottom-right-arrow"></div>
      </div>
    </>
  )
}

export default AuctionSection
