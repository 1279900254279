import homeOneImg from './img/desktop_hero_01.gif';
import homeTwoImg from './img/desktop_hero_02.jpg';
import homeThreeMp4 from './img/desktop_hero_03.jpg';
import homeShrinkThree from './img/hero_section_mobile.gif'
import homeShrinkTwoImg from './img/Mobile_Hero_02.jpeg'
import homeShrinkOneImg from './img/Mobile_Hero_03.jpeg'
import homeThreePoster from './img/home_three_poster.jpg';
import './HomeSection.css';
import './HomeSectionAnimation.css';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';

// import './HomeRightElement.scss'
// import './HomeSectionExtra.scss'
import React, { Component } from 'react';
import VhhpButton from '../../Elements/VhhpButton';
import VhhpButtonDonate from '../../Elements/VhhpButtonDonate';

const w = window.innerWidth

interface HomeSectionConfigItem {
    title: string;
    date?: string;
    body?: string;
    trElem: JSX.Element;    
}
const HomeSectionCycleInTime = 6000;
const HomeSectionCycleOutTime = 1000;

let slidingAnimation: string = "left_to_right"

//info for creating <link rel="preload" tags in document head.  No need to preload the first image of the slideshow
//the link tags are generated and appended to the head when component mounts
const PreloadSources: {src: string, as: string}[] = [
    // { src: homeOneImg, as: "image"},
    { src: homeTwoImg, as: "image"},
    {src: homeThreeMp4, as: "video"},
    { src: homeThreePoster, as: "image"}
]


const HomeSectionConfig: HomeSectionConfigItem[] = [
    {
        title: "Housing for a Healthier New York",
        date: "Oct 26 – 27, 2021",
        trElem: <video autoPlay width={w} loop preload="auto" controls={false} poster={w <= 990 ? homeShrinkThree: homeOneImg} className="tr-box_mp4"><source src={homeThreeMp4} type="video/mp4" /></video>
        
    },
    {
        title: "Housing for a Healthier New York",
        date: "Oct 26 – 27, 2021",    
        trElem: w <= 990 ? <img className="tr-box_img-container_img" src={homeShrinkTwoImg} alt="Brena Bracy Seals, Habitat NYC Homeowner" /> : <img className="tr-box_img-container_img" src={homeTwoImg} alt="Brena Bracy Seals, Habitat NYC Homeowner" />
    },
    {
        title: "Housing for a Healthier New York",
        date: "Oct 26 – 27, 2021",
        trElem: w <= 990 ? <img className="tr-box_img-container_img" src={homeShrinkOneImg} alt="" /> : <img className="tr-box_img-container_img" src={homeThreeMp4} alt="" />
    }
];

interface HomeSectionProps {

}

interface HomeSectionState {
    HomeCycle: number;
    HomeCycleState: "in" | "out";
}

export default class HomeSection extends Component<HomeSectionProps, HomeSectionState> {
    timerID: any;
    constructor(props: HomeSectionProps) {
        super(props);
        this.state = { HomeCycle: 0, HomeCycleState: "in"};        
    }
    
    homeCycle() {
        let cycle = this.state.HomeCycle;
        let cycleState = this.state.HomeCycleState;
        let newCycleState: "in" | "out" = cycleState === "in" ? "out" : "in";
        let newState: { HomeCycle: number, HomeCycleState: "in" | "out";};
        if (newCycleState === "out") {
            newState = {
                HomeCycle: cycle,
                HomeCycleState: newCycleState
            }
        } else {
            if (cycle === HomeSectionConfig.length - 1)
                newState = {
                    HomeCycle: 0,
                    HomeCycleState: newCycleState
                };
            else
                newState = {
                    HomeCycle: ++cycle,
                    HomeCycleState: newCycleState
                };
        }

        clearInterval(this.timerID);
        let timeout = newState.HomeCycleState === "in" ? HomeSectionCycleInTime : HomeSectionCycleOutTime;
        this.timerID = setInterval(
            () => this.homeCycle(),
            timeout);
        this.setState(newState);

    }
    componentDidMount() {
        //preload images for rotating slides
        PreloadSources.forEach(p => {
            let link = document.createElement("link");
            link.rel = "preload";
            link.href = p.src;
            link.as = p.as;
            document.head.appendChild(link);
        });

        // start rotating slides
        this.timerID = setInterval(
            () => this.homeCycle(),
            HomeSectionCycleInTime);
    }
    componentWillUnmount() {
        clearInterval(this.timerID)
    }
    render() {        
        let cycle = this.state.HomeCycle;
        let configItem = HomeSectionConfig[cycle];
        let animationClass = this.state.HomeCycleState === "in" ? " fade-in" : " fade-out";
        // const registerPath = `${ApplicationPaths.Register}`
        
        return (
            <div className="home-container">
            <div className="bl-box">
                <div className={`bl-box_content`}>
                    <div className="bl-box_title display">{configItem.title}</div>
                    <div className="bl-box_date display">{configItem.date}</div>
                    {/*<div className="bl-box_body body1 white">{configItem.body}</div>*/}
                    {/*<VhhpButton url="https://habitatnyc.org/women-build-summit-tickets/" text="Register" styleOptions={{ color: "white", outline: true, background: "dark-blue" }}></VhhpButton>*/}
                    {/*<a href={registerPath}><VhhpButton text="Register" styleOptions={{ color: "white", outline: true, background: "dark-blue" }}></VhhpButton></a>*/}
                    <a href={"https://habitatnycwc.org/donate-now/"}><VhhpButton text="Donate" styleOptions={{ color: "white", outline: true, background: "dark-blue" }}></VhhpButton></a>
                </div>
            </div>

            <div className="tr-box">
                <div className={`tr-box_img-container${animationClass}`}>
                    {configItem.trElem}
                </div>
            </div>

            </div>
        );
    }
}