import React, { Component } from 'react';
import vhhpUserService from '../../VhhpUserService';
import VhhpButton from '../Elements/VhhpButton';
import WizardLayout from './WizardLayout';
import "./InviteGuests.scss";
import checkImg from './img/icon_white_check.png';


//NOTE: the content in the following variable is also represented as HTML in the render below
const COPY_TO_CLIPBOARD_TEXT = `As we continue to heal from the COVID-19 crisis, New York’s future is more uncharted than ever. Will we return to a city of rising temperatures, racially disparate housing and soaring rents? Or will we come together and build a sustainable, equitable city for everyone?

Join me for the Women Build Summit, Habitat for Humanity New York City’s first ever all virtual conference, where we will celebrate New York’s sustainable future! This two-day event will feature inspiring speakers, amazing experiences and uplifting stories. You don’t want to miss out.

You are already on my guest list, so just follow this link to create your profile with the following guest code \"[guest_code]\" and attend the event! Come be part of New York’s bright future – one where everyone has a safe, sustainable, affordable place to live.`;
const GUEST_LINK = `https://identity.habitatnycwc.org/identity/account/register?type=guest&code=`

interface InviteGuestsProps {

}
interface InviteGuestsState {
    loading: boolean;
    loadingError: string;
    copyError: boolean;
    guestCode: string;
    guestLimit: number;
    itemCopied: boolean;
}

export default class InviteGuests extends Component<InviteGuestsProps, InviteGuestsState> {

    constructor(props: InviteGuestsProps) {
        super(props);

        this.state = { loading: true, itemCopied: false, copyError: false, loadingError: "", guestCode: "", guestLimit: 0 };
    }
    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        const vhhpUser = await vhhpUserService.getVhhpUser();
        console.log(vhhpUser)
        if(vhhpUser?.vhhpUserType === 2) {
            const sponsorGuest = await vhhpUserService.GetSponsorGuestsBySponsorID() || [];
            // console.log(sponsorGuest)
            console.log(sponsorGuest)
            const guestCode = vhhpUser?.invitedUser?.invitationCode.replace('SPN', 'GST')

            const guestLimit = sponsorGuest?.guestCode?.quantity
            const approvedGuestCount = sponsorGuest?.guests.length
            const sponsorGuests = sponsorGuest?.guests

            // const approvedGuestCount = sponsorGuest?.filter(g => !g.onSponsorWaitList).length || 0;
            this.setState({ loading: false, guestCode: guestCode, guestLimit: guestLimit });
        }
        // if (vhhpUser?.invitedUser?.invitationCode)
        //     this.setState({ loading: false, guestCode: vhhpUser?.invitedUser?.invitationCode.replace('SPN', 'GST'), guestLimit: 100 });
        else
            this.setState({ loading: false, loadingError: "An error has occured, please try again later from your Profile page." });
    }

    handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean> {
        //the AgendaView commits changes to DB as they happen
        //so no validation/commit to wait on when next is clicked
        return Promise.resolve(true);
    }

    handleCopyButtonClick(which: "invitation" | "link") {
        const guestLink = GUEST_LINK + this.state.guestCode;
        const content = which === "invitation"
            ? COPY_TO_CLIPBOARD_TEXT.replace('[guest_code]', this.state.guestCode) + "\n\n" + guestLink
            : guestLink;

        navigator.clipboard.writeText(content).then(
            () => {
                console.log("Copying to clipboard successful.");
                this.setState({ itemCopied: true });
            },
            () => {
                console.log("Unable to write to clipboard.");
                this.setState({ copyError: true });                
            });
    }

    render() {
        const isLoading = this.state.loading;
        const guestLimit = this.state.guestLimit;
        const loadingError = this.state.loadingError;
        const itemCopied = this.state.itemCopied;
        const copyError = this.state.copyError;

        const igCopyTitle = loadingError
            ? loadingError
            : `You are eligible to invite ${guestLimit} guests using the following guest code ${this.state.guestCode}.`;
        return (
            <WizardLayout
                onNextClick={(e) => this.handleNextButtonClick(e)}
                WizardContentPaneChildren={
                    <div className="rwcp-container">
                        <div className="rwcp-body">
                            <div className="rwcp-body__header">Invite Guests</div>
                            <div className="rwcp-body__text">
                                Your sponsorship package a guest registration link for you to share with your network. You and your guests will be able to access the exclusive virtual experiences and the full two-day program.                                 
                            </div>
                            <div className="ig-copy-container">
                                <div className={`ig-copy-copied gray-1 ${itemCopied ? "" : "ig-error--hidden"}`}>
                                    <img src={checkImg} alt="" className="ig-copy-copied__img" /> Copied to Clipboard
                                </div>

                                <div className="ig-copy-box">
                                    <div className={`ig-copy__title gray-1 ${loadingError ? " red-error" : ""}`}>{igCopyTitle}</div>
                                    <div className={`ig-copy__text body3 gray-1 ${loadingError ? " ig-error--hidden" : ""}`}>Copy and share the following with your guests.</div>
                                    <div className="ig-copy-buttons">
                                        <VhhpButton disabled={isLoading || loadingError !== ""} className="ig-copy-buttons__button" styleOptions={{ color: "blue", outline: true, background: "gray" }} onclick={e => this.handleCopyButtonClick("invitation")} text="Copy Invitation" />
                                        <VhhpButton disabled={isLoading || loadingError !== ""} className="ig-copy-buttons__button" styleOptions={{ color: "blue", outline: true, background: "gray" }} onclick={e => this.handleCopyButtonClick("link")} text="Copy Invite Link" />
                                    </div>
                                </div>
                                <div className={`ig-copy-error body2 ${copyError ? "" : "ig-copy-error-hidden"}`}>
                                    <p className="red-error">We were unable to automatically copy the message to your clipboard.</p>
                                    <p>Here is your Invite Link: <span className="body3">{GUEST_LINK + this.state.guestCode}</span></p>
                                    <p>Here is your Invitation:</p>
                                    <p className="body3">As we continue to heal from the COVID-19 crisis, New York’s future is more uncharted than ever. Will we return to a city of rising temperatures, racially disparate housing and soaring rents? Or will we come together and build a sustainable, equitable city for everyone?</p>
                                    <p className="body3">Join me for the Women Build Summit, Habitat for Humanity New York City’s first ever all virtual conference, where we will celebrate New York’s sustainable future! This two-day event will feature inspiring speakers, amazing experiences and uplifting stories. You don’t want to miss out.</p>
                                    <p className="body3">You are already on my guest list, so just follow this link to create your profile with the following guest code "{this.state.guestCode}" and attend the event! Come be part of New York’s bright future – one where everyone has a safe, sustainable, affordable place to live.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                }>
            </WizardLayout>
        );
    }
}