import React from 'react';
import './SponsorsSection.scss';
import imgGradient from './sponsors_gradient.png';
import VhhpButton from '../../../Elements/VhhpButton'

const SponsorsSectionLeft = ({ sponsorType, setSponsorType }) => {
  const handleClick = (e) => {
    setSponsorType(e.target.id);
    // console.log(e.target.id);
  };

  return (
    <div className='sponsors-left-container'>
    <div className="sponsor-top-left-arrorw"></div>
      <div className='sponsors-left-content'>
        <div className='sponsors-left-content_header label--primary white'>
          Sponsors
        </div>

        <div className='sponsors-left-content_title h1 white'>
          Thank You to Our 2021 Habitat House Party Sponsors
        </div>
        <div className='white' style={{ marginTop: '20px' }}>
          <p>Habitat House Party Sponsors not only help to build a healthier New York, but will have their brand seen by thousands of New Yorkers.</p>
        </div>
        <div   style={{ marginTop: '20px' }}>
        <a href='https://habitatnycwc.org/donate-now/'>
          <VhhpButton
            text='Learn More'
            styleOptions={{
              color: 'white',
              background: 'transparent',
              outline: true,
            }}
          ></VhhpButton>
        </a>
        </div>

      </div>


      {/*<img alt="" src={imgGradient} className="sponsors-left-content__img" />*/}
    <div className="sponsor-bottom-right-arrorw"></div>
    </div>
  );
};

export default SponsorsSectionLeft;
