import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import Avatar from './Avatar';
import ReactModal from 'react-modal';
import ProfileAvatar from '../registration/ProfileAvatar';
import "./AvatarField.css";
import { SelectedAvatar } from './AvatarCollection';


export type AvatarSource = "UserInitials" | string;

ReactModal.setAppElement('#root');

const AvatarField = (props: FieldHookConfig<string> & { firstName: string, lastName: string }) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    function saveAndCloseModal(selectedAvatar: SelectedAvatar) {        
        if (selectedAvatar.isInitialsAvatar) {
            setValue("UserInitials");            
        }
        if (selectedAvatar.avatarUrl)
            setValue(selectedAvatar.avatarUrl);
        closeModal();
    }

    function avatarModal() {
        return <ReactModal
            className="af-modal"
            overlayClassName="af-modal-overlay"
            isOpen={modalIsOpen}
            onRequestClose={() => closeModal()}
            shouldCloseOnOverlayClick={true}
        >
            <ProfileAvatar firstName={props.firstName} lastName={props.lastName} closeModal={() => closeModal()} saveAndCloseModal={(x) => saveAndCloseModal(x)} />

        </ReactModal>
    }

    const isInitialsAvatar = value === "UserInitials" || value === "";
    const avatarUrl = isInitialsAvatar ? "" : value as string;
    return (
        <div className="af-container">
            <Avatar
                wrapperClass="af-container__avatar"
                size="Large"
                source={isInitialsAvatar ? "UserInitials" : avatarUrl}
                firstName={props.firstName}
                lastName={props.lastName}
            />
            <a className="a af-container__change" onClick={() => openModal()}>Change picture</a>
            {avatarModal()}
        </div>
    );
}

export default AvatarField;