import React from 'react';

const SpeakerContainer = ({ speaker }) => {
  return (
    <div className='video-info__speaker'>
      <div className='video-info__speaker-image'>
        {speaker.imgUrl === (null || '') ? (
          ''
        ) : (
          <img src={speaker.imgUrl} alt='' />
        )}
      </div>

      <div className='video-info__about-speaker'>
        <div className='video-info__speaker-name h3 white'>{speaker.name}</div>
        <div className='label--primary white'>{speaker.title}</div>
      </div>
    </div>
  );
};

export default SpeakerContainer;
