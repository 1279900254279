import React, { Fragment } from 'react';
import TopMenu from '../Menus/TopMenu';
import OnDemandVideosRight from './OnDemandVideosRight';
import './OnDemandVideos.scss';
import SponsorMenu from '../Menus/SponsorMenu';
import SubMenu from '../Menus/SubMenu';
import FooterMenu from '../Menus/FooterMenu';

const OnDemandVideos = (props) => {
  const pathname =
    props.match.path === '/ondemandvideos' ? 'Videos on Demand' : '';

  return (
    <Fragment>
      <TopMenu theme='dark' />
      <div className='demand-videos__breadcrum'>
        <SubMenu color='#3b3b3b' pathname={pathname} />
      </div>
      <div className='demand-videos__container container-- container--px'>
        <div className='demand-videos__header display blue-3'>
          Videos on demand
        </div>
      </div>

      <div className='demand-videos__menu-top container--pl'>
        <div className='h4'>Virtual Exclusive Activities</div>
        <div className='h4'>Making a Difference</div>
      </div>

      <div className='demand-videos__max-content'>
        <div className='demand-videos__content'>
          <div className='demand-videos__title gray-1 container--py container--pl'>
            Virtual <span>Experience Activities</span>
          </div>

          <div className='demand-videos__cards container--py '>
            <OnDemandVideosRight type={'live'} />
          </div>
        </div>

        <div className='demand-videos__content'>
          <div className='demand-videos__title gray-1 container--pb container--pl'>
            Making a Difference
          </div>

          <div className='demand-videos__cards container--pb '>
            <OnDemandVideosRight type={'featured'} />
          </div>
        </div>
      </div>

      <div className='demand-videos__sponsor-menu'>
        <SponsorMenu />
      </div>
      <FooterMenu />
      <div className="help">Need Help?</div>
    </Fragment>
  );
};

export default OnDemandVideos;
