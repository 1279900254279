import * as t from '../types';
export const showRooms = (value) => (dispatch) => {
  dispatch({
    type: t.SHOW_ROOMS,
    payload: value,
  });
};

export const setView = (value) => (dispatch) => {
  dispatch({
    type: t.SET_VIEW,
    payload: value,
  });
};
