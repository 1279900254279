import React, { Component } from 'react';
import AgendaView from '../Agenda/AgendaView';
import FooterMenu from '../Menus/FooterMenu';
import TopMenu from '../Menus/TopMenu';
import './Profile.scss';
import VhhpButton from '../Elements/VhhpButton';

import gearImg from "./img/gear.svg";
import motifImg from "./img/motif_gradient.png";
import { Link } from 'react-router-dom';
import vhhpUserService, { Sponsor, UserProfile, VhhpUserType } from '../../VhhpUserService';
import VhhpButtonDonate from '../Elements/VhhpButtonDonate';
import ManageGuests from './ManageGuests';
import bgImage from './img/profile_background.png'
import SponsorMenu from '../Menus/SponsorMenu';


interface ProfileState {
    loading: boolean;
    userProfile: UserProfile | null;
    usersSponsor: Sponsor | null;
    guestType: VhhpUserType | null;
    avatar: JSX.Element | undefined;
    invitationCode: string;
}

export default class Profile extends Component<any, ProfileState> {
    constructor(props: any) {
        super(props);

        this.state = { loading: true, userProfile: null, usersSponsor: null, guestType: null, avatar: <></>, invitationCode: '' };
    }
    async componentDidMount() {
        // console.log(this.props)
        const vhhpUser = await vhhpUserService.getVhhpUser();
        const avatar = await vhhpUserService.getAvatarForCurrentUser("Large");
        console.log("profile vhhp user", vhhpUser)
        
        if (vhhpUser?.profile)
        {
            // check if the profile section are completed, if not, then redirect to the create profile section
            let shouldRedirectToTermsCondition = false
            const redirectUrl = `${window.location.origin}/register/termsconditions`
            // const requiredFields = ["firstName", "lastName", "userMailingAddress", "userState", "userUnitNo", "userZipCode", "companyName", "jobTitle", "industry"]
            if(!vhhpUser?.profile?.firstName) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.lastName) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.userMailingAddress) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.userState) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.userUnitNo) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.userZipCode) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.companyName) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.jobTitle) shouldRedirectToTermsCondition = true
            if(!vhhpUser?.profile?.industry) shouldRedirectToTermsCondition = true
            // console.log(`need to redirect ${shouldRedirectToTermsCondition}`)
            if(shouldRedirectToTermsCondition) return window.location.replace(redirectUrl)

            this.setState({
                loading: false,
                guestType: vhhpUser.vhhpUserType,
                userProfile: vhhpUser.profile,
                usersSponsor: vhhpUser.sponsor || null,
                avatar: avatar
            });
        }

    }

    render() {
        const isLoading = this.state.loading;
        // const userProfile = this.state.userProfile;
        // const usersSponsor = this.state.usersSponsor;
        // const guestType = this.state.guestType;
        // const avatar = this.state.avatar;
        // const invitationCode = this.state.invitationCode
        const { userProfile, usersSponsor, guestType, avatar, invitationCode } = this.state

        let usersName = "", titleCompany = "", invitedGuest = "";

        if (!isLoading && userProfile) {
            usersName = `${userProfile.firstName || ""} ${userProfile.lastName || ""}`;
            titleCompany = `${userProfile.jobTitle || ""} at ${userProfile.companyName || ""}`;

            if (guestType === VhhpUserType.InvitedGuest && usersSponsor)
                invitedGuest = `Invited Guest of ${usersSponsor.name}`;
        }

        const btmRightContent = guestType === VhhpUserType.Sponsor
            ? <ManageGuests/>
            : <>
                <div className="pfl-donate">
                    <div className="pfl-donate__text gray-2">Learn more about 2021 Habitat House Party sponsorship opportunities and after party tickets for the in-person event!</div>
                    <VhhpButtonDonate text="Learn More" styleOptions="standard" donateUrl="https://habitatnycwc.org/2021-sponsorships/" />
                </div>
                {/* <img src={motifImg} className="pfl-motif__img" /> */}
            </>

        return (
            <div className="pfl-container">
                <TopMenu></TopMenu>
                {/*<div className="pfl-top-row">

                </div>*/}
                <div className="pfl-btm-row">
                    
                    <div className="pfl-btm-row__left">
                        <div className="left-column-filler">
                            <div className="left-column-white-brick"></div>
                        </div>
                        <div className="white-brick-container">

                        <div className="pfl-top-row__user">
                            <div className="pfl-user__avatar">{avatar}</div>
                            <div className="pfl-user-info">
                                <div className="pfl-user-info__name h1 gray-1">{usersName}</div>
                                <div className="pfl-user-info__title body2 gray-1">{titleCompany}<br />{invitedGuest}</div>
                                <Link to="/profileedit" className="a"><img src={gearImg} alt="Edit Profile" className="pfl-edit-profile__img" width="16" height="16" />Edit Profile</Link>
                            </div>

                        </div>
                        <div className="pfl-agenda-title gray-1">My Agenda</div>
                        {guestType !== null && guestType !== VhhpUserType.Sponsor && guestType !== VhhpUserType.InvitedGuest ? <div className="pfl-user-info__title body2 gray-1">This event is limited to sponsor only. Enter your invitation code or <a href="https://habitatnyc.org/women-build-summit-tickets/" target="_blank">click here</a> to purchase the ticket.
                            <form>
                                <label>Invitation Code: 
                                    <input style={{ marginLeft: 10 }} type="text" value={invitationCode} className='invitation-code-input' onChange={e => {
                                        this.setState({
                                            invitationCode: e.target.value
                                        })
                                    }} />
                                </label>
                                <VhhpButton onclick={async (e) => {
                                    // console.log("update sponsor code")
                                    // console.log(`the invitation code is ${invitationCode}`)
                                    const r = await vhhpUserService.updateUserInvitationCode(invitationCode)
                                    // console.log(r)
                                    if(r)
                                    {
                                        // update the reducer to populate the latest data
                                        return window.location.reload()
                                    }
                                    return 
                                    // await this.handleSave();
                                    // window.location.href = '/profile'
                                }} className="pe-save-button" text="Add Code" styleOptions="standard" />
                            </form>
                        </div>:null}
                        <AgendaView fullHeight={false} />
                        
                        </div>

                    </div>
                    <div className={`pfl-btm-row__right ${guestType === VhhpUserType.Sponsor ? "pfl-btm-row__right--sponsor" : "pfl-btm-row__right--donate"}`}>
                        {btmRightContent}
                    </div>
                </div>
                <div>           
                    <SponsorMenu />
                </div>
                <FooterMenu />
            </div>
        );
    }
}