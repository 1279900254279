//interaction types
export const SHOW_ROOMS = 'SHOW_ROOMS';
export const SET_VIEW = 'SET_VIEW';

//auth types
export const GET_VHHP_USER = 'GET_VHPP_USER';
export const GET_AUTH_STATUS = 'GET_AUTH_STATUS';

//dashboard
export const GET_EVENT = 'GET_EVENT';
export const GET_AGENDAS = 'GET_AGENDAS';
export const SET_DAY = 'SET_DAY';
export const SET_DETAILS = 'SET_DETAILS';


//user
export const GET_ALL_USERS = 'GET_ALL_USERS';

export const ADD_ONLINE_USER = 'ADD_ONLINE_USER';
export const ADD_ONLINE_USERS = 'ADD_ONLINE_USERS';
export const REMOVE_ONLINE_USER = 'REMOVE_ONLINE_USER';


export const SET_CONTEXT = 'SET_CONTEXT';
