import * as t from '../types';
import agendaService from '../../AgendaService';
import moment from 'moment-timezone';

const BACKEND = process.env.REACT_APP_DASHBOARD || 'https://d.virtualhabitathouseparty.org/'

export const getEvent = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    fetch(`${BACKEND}api/v1/admin/conferenceRoom`)
      .then((response) => response.json())
      .then((res) => {
        const rooms = res?.data?.room;
        const room = rooms.find((room) => room.name == 'Header');
        dispatch({
          type: t.GET_EVENT,
          payload: {
            single: room,
            all: rooms,
          },
        });
        resolve(room);
      })
      .catch((err) => {
        console.log(err);
        //reject(Error('Failed to get Event.'));
      });
  });
};

export const getAgendas = () => async (dispatch) => {
  try {
    const agendas = await agendaService.getEvents();
    // console.log(agendas);

    const mondayData =
      agendas &&
      agendas
        .filter((el) => el.day === 26)
        .sort((a, b) => a.sortOrder - b.sortOrder);

    const tuesdayData =
      agendas &&
      agendas
        .filter((el) => el.day === 27)
        .sort((a, b) => a.sortOrder - b.sortOrder);

    dispatch({
      type: t.GET_AGENDAS,
      payload: { agendas, mondayData, tuesdayData },
    });
  } catch (err) {
    console.error(err);
  }
};

export const setDay = (day, hour, nowDT) => (dispatch) => {
  dispatch({
    type: t.SET_DAY,
    payload: { day, hour, nowDT },
  });
};

export const setDetails = (mondayData, currHour) => (dispatch) => {
  let nowPlaying;
  let upNext;

  for (let i = 0; i < mondayData.length; i++) {
    let t1 = moment(mondayData[i].startDateTime)
      .tz('America/New_York')
      .format('HH');
    let t2 = moment(mondayData[i].endDateTime)
      .tz('America/New_York')
      .format('HH');

    currHour = currHour;

    //console.log('parse', { currHour, t1, t2 });

    if (currHour >= t1 && currHour <= t2) {
      nowPlaying = mondayData[i];
      upNext = mondayData[i + 1];
    }
  }

  dispatch({
    type: t.SET_DETAILS,
    payload: { nowPlaying, upNext },
  });
};
