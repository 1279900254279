import vhhpUserService from '../../VhhpUserService';
import authService from '../../components/api-authorization/AuthorizeService';
import * as t from '../types';

export const getVhhpUser = () => async (dispatch) => {
  // console.log('vhhp redux called')
  try {
    const vhhpUserData = await vhhpUserService.getVhhpUser();
    // console.log('get vhhp user called')
    // console.log(vhhpUserData)
    // presence(vhhpUserData);
    // const sponsorsGuests = await vhhpUserService.GetSponsorGuestsBySponsorID("25898be5-85ea-4b8e-b355-45225e22e653")
    // console.log(sponsorsGuests)
    presence(vhhpUserData);
    dispatch({
      type: t.GET_VHHP_USER,
      payload: vhhpUserData,
    });
    return vhhpUserData
  } catch (err) {
    console.error(err);
    return false
  }
};

export const getAuthStatus = () => async (dispatch) => {
  try {
  const isAuthenticated= await authService.checkAuthState()
    dispatch({
      type: t.GET_AUTH_STATUS,
      payload: isAuthenticated,
    });
  } catch (err) {
    console.error(err);
  }
};

// Called asap the website is loaded. Website gets all user data on the client side, 
// and website uses Pusher to get who's online. 
export const getAllUsers = () => async (dispatch) => {
  // return []
  const apiURL = process.env.REACT_APP_VHHP_API || 'https://identity.habitatnycwc.org/'
  return new Promise((resolve, reject) => {
    fetch(apiURL+'/api/users/all')
      .then((response) => response.json())
      .then((res) => {
        const users = res.map((u) => {
          vhhpUserService.addFunctionsToVhhpUser(u);
          return u;
        });
        //console.error({ res })
        dispatch({
          type: t.GET_ALL_USERS,
          payload: users,
        });
        resolve(users);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const presence = (context, user = null) => async (dispatch) => {
  dispatch({
    type: t.SET_CONTEXT,
    payload: context,
  });

  if (user) {
    dispatch({
      type: t.GET_VHHP_USER,
      payload: user,
    });
  }
};
