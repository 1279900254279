import React from 'react';
import './SponsorMenu.scss';
import jsonData from './SponsorLogos.json';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import Slider from 'react-slick';

const SponsorMenu = () => {
  let logos = jsonData.map((elem) => (
    <a href={elem.url} target='_blank' className='sponsor-menu__logos'>
      <img src={`/images/sponsors_carousel/${elem.image}`} alt='' />
    </a>
  ));

  let i = 0;
  let items = [];

  while (i < logos.length) {
    if (logos.length - i >= 4) {
      let slicelogos = logos.slice(i, i + 4);

      items.push(
        <div className='sponsor-menu__logo-wrapper'>
          {slicelogos.map((elem) => (
            <div className='sponsor-menu__logo'>{elem}</div>
          ))}
        </div>
      );
    } else {
      let remaininglogos = logos.slice(i, logos.length);

      items.push(
        <div className='sponsor-menu__logo-wrapper'>
          {remaininglogos.map((elem, i) => (
            <div key={i} className='sponsor-menu__logo'>
              {elem}
            </div>
          ))}
        </div>
      );
    }
    i += 4;
  }

  const arrays = [
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[0]}</div>
      <div className='sponsor-menu__logo'>{logos[1]}</div>
      <div className='sponsor-menu__logo'>{logos[2]}</div>
      <div className='sponsor-menu__logo'>{logos[3]}</div>
      <div className='sponsor-menu__logo'>{logos[4]}</div>
    </div>,
    <div className='sponsor-menu__logo-wrapper'>
    <div className='sponsor-menu__logo'>{logos[5]}</div>

      <div className='sponsor-menu__logo'>{logos[6]}</div>
      <div className='sponsor-menu__logo'>{logos[7]}</div>
      <div className='sponsor-menu__logo'>{logos[8]}</div>
      <div className='sponsor-menu__logo'>{logos[9]}</div>

    </div>,
    <div className='sponsor-menu__logo-wrapper'>
    <div className='sponsor-menu__logo'>{logos[10]}</div>
    <div className='sponsor-menu__logo'>{logos[11]}</div>
      <div className='sponsor-menu__logo'>{logos[12]}</div>
      <div className='sponsor-menu__logo'>{logos[13]}</div>
      <div className='sponsor-menu__logo'>{logos[14]}</div>

    </div>,
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[15]}</div>
      <div className='sponsor-menu__logo'>{logos[16]}</div>
      <div className='sponsor-menu__logo'>{logos[17]}</div>
      <div className='sponsor-menu__logo'>{logos[18]}</div>
      <div className='sponsor-menu__logo'>{logos[19]}</div>

    </div>,
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[20]}</div>
      <div className='sponsor-menu__logo'>{logos[21]}</div>
      <div className='sponsor-menu__logo'>{logos[22]}</div>
      <div className='sponsor-menu__logo'>{logos[23]}</div>
      <div className='sponsor-menu__logo'>{logos[24]}</div>

    </div>,
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[25]}</div>
      <div className='sponsor-menu__logo'>{logos[26]}</div>
      <div className='sponsor-menu__logo'>{logos[27]}</div>
      <div className='sponsor-menu__logo'>{logos[28]}</div>
      <div className='sponsor-menu__logo'>{logos[29]}</div>
    </div>,
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[30]}</div>
      <div className='sponsor-menu__logo'>{logos[31]}</div>
      <div className='sponsor-menu__logo'>{logos[32]}</div>
      <div className='sponsor-menu__logo'>{logos[33]}</div>
      <div className='sponsor-menu__logo'>{logos[34]}</div>
    </div>,
    <div className='sponsor-menu__logo-wrapper'>
      <div className='sponsor-menu__logo'>{logos[35]}</div>
      <div className='sponsor-menu__logo'>{logos[36]}</div>
      <div className='sponsor-menu__logo'>{logos[37]}</div>

    </div>
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='sponsor-menu'>
      <div className='sponsor-menu__header flex'>
        <p>OUR SPONSORS</p>
      </div>
      <div className='sponsor-menu__carousel'>
        <Slider {...settings}>{arrays}</Slider>
      </div>
    </div>
  );
};

export default SponsorMenu;
