import './Signup.css';
import React, { Component } from 'react';
import Infobox from './Infobox';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import VhhpInput from '../Elements/VhhpInput';
import VhhpButton from '../Elements/VhhpButton';
import { Link } from 'react-router-dom';


//TODO: dont forget about the reset code

interface ResetPasswordState {
    error: string | null;
    confirm: string | null;
}
export default class ResetPassword extends Component<any, ResetPasswordState> {
    constructor(props: any) {
        super(props);

        this.state = { error: null, confirm: null };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let error = urlParams.get("error");
        let confirm = urlParams.get("confirm")
        this.setState({ error, confirm });
    }

    getResetCode() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("code");
    }

    getForm() {
        return <Formik
            initialValues={{
                Email: "",
                Password: "",
                ConfirmPassword: ""
            }}
            validationSchema={
                Yup.object({
                    Email: Yup.string()
                        .email("Invalid email address")
                        .required(),
                    Password: Yup.string()
                        .min(6)
                        .max(100)
                        .required("Required"),
                    ConfirmPassword: Yup.string()
                        .oneOf([Yup.ref("Password"), ""], "Passwords must match")
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);                
                (document.getElementById("resetPasswordForm") as HTMLFormElement).submit();
            }}
        >
            {({ isSubmitting }) => (
                <Form id="resetPasswordForm" method="POST" action="/Identity/Account/ResetPassword">
                    <VhhpInput type="text" label="Email Address" name="Email" />
                    <VhhpInput type="password" label="Password" name="Password" />
                    <VhhpInput type="password" label="Confirm Password" name="ConfirmPassword" />
                    <input type="hidden" name="Code" value={this.getResetCode() || ""} />
                    {this.state.error && <div className="input-error red-error">{this.state.error}</div>}
                    <div className="form-box__password-requirements form-box__text gray-1">Passwords must be at least six characters long and contain at least one alphanumeric character, at least one digit, at least one lowercase letter, and at least one uppercase letter.</div>
                    <br />
                    <VhhpButton disabled={isSubmitting} type="submit" styleOptions="standard" text="Submit" className="button__next-button--wide" />
                </Form>
            )}
        </Formik>
    }

    getPasswordReset() {
        return <div>
            <div className="body1 green-success"><p>Your password has been reset.</p></div>
            <VhhpButton styleOptions="standard" text="Return to Sign In Page" className="button__next-button--wide" url="/signin" />
        </div>

    }

    render() {

        return (
            <div className="signup-container">
                <Infobox />

                <div className="form-box">

                    <div className="form-box-content">
                        <div className="form-box__title gray-1">Reset Password</div>
                        <div className="form-box__text form-box__text-top gray-1">Please complete the form below to reset your password, or click <Link to="/signin" className="a-inline">here</Link> to sign in.</div>
                        {this.state.confirm && this.getPasswordReset()}
                        {!this.state.confirm && this.getForm()}


                    </div>
                </div>
            </div>


        );
    }
}