import React from 'react';
import YouTube from 'react-youtube';

class Youtube extends React.Component {

    state = {
        id: this.props?.id || "q2CB5gjyrqs"
    }

    componentDidMount() {
        if (this.props?.link) {
            this.setState({ id: this.getId(this.props?.link) })
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if ((nextProps?.link != this.props?.link) && this.props?.link) {
            this.setState({ id: this.getId(nextProps?.link) })
        }
    }

    getId = (url) => {
        var ID = '';
        url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
        }
        else {
            ID = url;
        }
        return ID;
    }

    render() {
        const opts = {
            height: '640px',
            width: window.location.href.includes('conference') ? '70%' : '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                fs: 1, //fullscreen
                iv_load_policy: 3,
                rel: 0,
            },
        };

        const { id } = this.state

        return <div style={this.props?.style}>
            <YouTube videoId={id} opts={opts} onReady={this._onReady} />
        </div>

    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
        // console.log(event.target)
    }
}

export default Youtube