import { combineReducers } from 'redux';
import interactions from './interactions';
import user from './user';
import dashboard from './dashboard';


export default combineReducers({
  interactions,
  user,
  dashboard
});
