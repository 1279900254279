import React from 'react';

class Image extends React.Component {

    state = {
        link: this.props?.link || require('../../common/img/conference/default.png')
    }

    componentDidMount() {

    }

    // componentWillReceiveProps(nextProps) {
    //     // You don't have to do this check first, but it can help prevent an unneeded render
    //     if ((nextProps?.link != this.props?.link) && this.props?.link) {
    //         this.setState({ id: this.getId(nextProps?.link) })
    //     }
    // }

    render() {
        const style = {
            height: '640px',
            width: '70%',
        };

        return <div style={ { ...style, ...this.props?.style } }>
            <img src={this.state.link} alt="background"></img>
        </div>
        
    }
}

export default Image