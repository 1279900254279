import * as t from '../types';

const initialState = {
  event: null,
  events: [],
  agendas: [],
  mondayData: [],
  tuesdayData: [],
  loading: true,
  currDay: null,
  currHour: null,
  nowDT: null,
  nowPlaying: null,
  upNext: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case t.GET_EVENT:
      return {
        ...state,
        event: action.payload.single,
        events: action.payload.all,
      };
    case t.GET_AGENDAS:
      return {
        ...state,
        agendas: action.payload.agendas,
        mondayData: action.payload.mondayData,
        tuesdayData: action.payload.tuesdayData,
        loading: false,
      };
    case t.SET_DAY:
      return {
        ...state,
        currDay: action.payload.day,
        currHour: action.payload.hour,
        nowDT: action.payload.nowDT
      };
    case t.SET_DETAILS:
      return {
        ...state,
        nowPlaying: action.payload.nowPlaying,
        upNext: action.payload.upNext,
      };
    default:
      return {
        ...state,
      };
  }
};

export default dashboard;
