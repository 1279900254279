import React, { useState, useEffect } from 'react';
import RoomsCards from '../roomsCards/RoomsCards';
import './AllRooms.scss';
import Dropdown from './Dropdown';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { array } from 'yup';
import roomInfo from '../roomInfo.json';
import { presence } from '../../../../../redux/actions/user';

const CONTEXT_PREFIX = process.env.REACT_APP_CHAT_CONTEXT_PREFIX;

const AllRooms = ({ company, presence, online, user }) => {
  const [roomType, setRoomType] = useState('');

  const [filteredRooms, setFilteredRooms] = useState(roomInfo);

  const [filteredRoomsSearch, setFilteredRoomsSearch] = useState(null);

  const [text, setText] = useState('');

  const [flag, setFlag] = useState(false);
  const [presenceCalled, setPresenceCalled] = useState(false);

  useEffect(() => {
    sortRooms();
    //setFilteredRooms(sortRooms());
    setFlag((currFlag) => true);
    console.log('SORTING ROOMS again');
  }, [online]);

  if (user && !presenceCalled) {
    setPresenceCalled((value) => true);
    user && presence(CONTEXT_PREFIX + 'AllRooms');
  }

  useEffect(() => {
    filterByFilled();
  }, [flag]);

  useEffect(() => {
    filterData(roomType);
  }, [roomType]);

  useEffect(() => {
    filterData(text);
  }, [text, filteredRooms]);

  let subNames = [];

  let industries = [
    'Agriculture',
    'Apparel',
    'Banking',
    'Biotechnology',
    'Chemicals',
    'Communications',
    'Construction',
    'Consulting',
    'Education',
    'Electronics',
    'Energy',
    'Engineering',
    'Entertainment',
    'Environmental',
    'Finance',
    'Food',
    'Government',
    'Healthcare',
    'Hospitality',
    'Insurance',
    'Legal',
    'Machinery',
    'Manufacturing',
    'Media',
    'Not for Profit',
    'Real Estate',
    'Recreation',
    'Retail',
    'Shipping',
    'Technology',
    'Telecommunications',
    'Transportation',
    'Utilities',
    'Other',
  ];

  let selectList = [...industries];

  // online > profile.firstName, profile.lastName
  // online > context

  const sortRooms = () => {
    let reducedPeople = '';

    let rooms = [...roomInfo];

    rooms.map((item, index) => {
      //console.log('online ', online, item);
      let people = online.filter(
        (value) =>
          value.context.includes('_') &&
          value.context.split('_')[1] === item.subRoomName
      );

      if (people.length) {
        reducedPeople = people.reduce((total, person) => {
          return total + ' ' + person?.name;
        }, '');
      }

      if (people.length) {
        rooms[index] = {
          ...rooms[index],
          count: people.length,
          people: reducedPeople,
        };
      } else {
        rooms[index] = { ...rooms[index], count: 0, people: '' };
      }
      //console.log('FILTERED ROOMS: ', filteredRooms[index]);
    });

    let sortedRooms = rooms.sort((a, b) => b.count - a.count);
    console.log(sortedRooms);
    setFilteredRooms(sortedRooms);
  };

  const filterByFilled = () => {
    let indexes = [];

    let rooms = filteredRooms;

    rooms.map((elem, index) => {
      if (elem.count === 30) {
        indexes.push(elem);
      }
    });

    for (let i = 0; i < indexes.length; i++) {
      let id = rooms.indexOf(indexes[i]);
      if (id != -1) {
        rooms.splice(id, 1);
      }
    }

    rooms.push(...indexes);

    setFilteredRooms(rooms);
  };

  const handleChange = (e) => {
    setText(e.target.value);

    // console.log('text', text);
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setFilteredRoomsSearch(null);
    else {
      const filteredData = filteredRooms.filter((item) => {
        // console.log(item);
        return Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      console.log('filtered data new: ', filteredData);
      setFilteredRoomsSearch(filteredData);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  return (
    <div className='allrooms'>
      <div className='allrooms__title body1'>All Rooms</div>
      <div className='allrooms__top-menu flex'>
        <div className='allrooms__left-menu flex'>
          <div className='allrooms__room-dropdown'>
            <Dropdown
              placeholder='Select a Field'
              roomType={roomType}
              onChange={(v) => setRoomType(v)}
              options={selectList}
            />
          </div>
          <div className='allrooms__search'>
            <img src={require('../../img/search-icon.png')} alt='' />
            <input
              className='body3'
              value={text}
              type='text'
              placeholder='Search'
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='allrooms__room-numbers body3 gray-1'>
          Total {roomInfo.length} rooms
        </div>
      </div>

      <div className='allrooms__cards'>
        {(filteredRoomsSearch ? filteredRoomsSearch : filteredRooms).map(
          (item, idx) => {
            return <RoomsCards item={item} key={`${item.subRoomName}-${idx}`} />;
          }
        )}
      </div>
      {/* <div className='allrooms__cards'>
        <Slider {...settings}>
          {
            filteredRooms.map(item => (

              <RoomsCards item={item} />
            )
            )
          }
        </Slider>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  company: state.user.company,
  online: state.user.online,
  user: state.user.userData && state.user.userData,
});

export default connect(mapStateToProps, { presence })(AllRooms);
