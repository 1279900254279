import React, { useState, useEffect } from 'react'
import './SubMenu.scss'
import { Link, useHistory } from 'react-router-dom'

const SubMenu = ({ color, pathname, sponsor, fromRoomDotJs }) => {
  let history = useHistory()
  const [colorText, setColorText] = useState('')

  return (
    <div className="submenu">
      <div onClick={() => history.goBack()}>
        <svg
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8807 6.83347L7.36173 0.145776C7.17093 -0.0480709 6.85292 -0.0480709 6.66212 0.145776L0.143101 6.83347C-0.0477002 7.02731 -0.0477002 7.35039 0.143101 7.54424L0.842703 8.25501C1.0335 8.44885 1.3515 8.44885 1.54231 8.25501L6.66212 2.98885C6.85292 2.79501 7.17093 2.79501 7.36173 2.98885L12.4815 8.2227C12.6723 8.41654 12.9903 8.41654 13.1811 8.2227L13.8807 7.51193C14.0398 7.31808 14.0398 7.02731 13.8807 6.83347Z"
            fill={color}
          />
        </svg>
      </div>

      <div className="submenu__route">
        <Link
          to="/"
          className={`body2 ${
            color === 'white' ? 'white' : color === 'blue' ? 'blue-3' : 'gray-1'
          }`}
        >
          Home
        </Link>
        {/* // * From OnDemandVideos.js only */}
        {pathname && (
          <Link
            to="/ondemandvideos"
            className={`body2 ${
              color === 'white'
                ? 'slash-white white'
                : color === 'blue'
                ? 'slash-blue blue-3'
                : 'slash-dark gray-1'
            }`}
          >
            {pathname}
          </Link>
        )}
        {/* // * From Room.js only  START */}
        {fromRoomDotJs && (
          <Link
            to="/#rooms"
            className={`body2 ${
              color === 'white'
                ? 'slash-white white'
                : color === 'blue'
                ? 'slash-blue blue-3'
                : 'slash-dark gray-1'
            }`}
          >
            Watch Rooms
          </Link>
        )}
        {sponsor && (
          <Link
            to="#"
            className={`body2 ${
              color === 'white'
                ? 'slash-white white'
                : color === 'blue'
                ? 'slash-blue blue-3'
                : 'slash-dark gray-1'
            }`}
          >
            {sponsor}
          </Link>
        )}
        {/* // * From Room.js only  END */}
      </div>
    </div>
  )
}

export default SubMenu
