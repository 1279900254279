import s from './DuringHome.module.css';
import React, { useState, useEffect, useRef } from 'react';
import TopMenu from '../Menus/TopMenu';
// import HomeSection from './sections/homeSection/HomeSection';
import HomeSection from '../preevent/sections/HomeSection';
import AgendaSection from '../preevent/sections/AgendaSection';
import AboutSection from '../preevent/sections/AboutSection';
import SpeakersSection from '../preevent/sections/SpeakersSection';
import SponsorsSection from './sections/sponsorsSection/SponsorsSection';
// import SponsorsSection from './sections/SponsorsSection';
// import SponsorMenu from '../Menus/SponsorMenu'

import SupportSection from '../preevent/sections/SupportSection';
import RegisterSection from '../preevent/sections/RegisterSection';
import FooterMenu from '../Menus/FooterMenu';
import AuctionSection from './sections/auctionSection/AuctionSection';
import RoomsSection from './sections/roomsSection/RoomsSection';
import InfoSection from './sections/infoSection/InfoSection';
import { connect } from 'react-redux';
import { getVhhpUser, getAuthStatus } from '../../redux/actions/user';
import { getAgendas, setDay, setDetails } from '../../redux/actions/dashboard';
import moment from 'moment-timezone';

const DuringHome = ({
  view,
  getVhhpUser,
  getAuthStatus,
  userData,
  getAgendas,
  setDay,
  setDetails,
  currDay,
  currHour,
  mondayData,
  isAuthenticated,
  location,
}) => {
  let navTargetClass = s['pre-event-nav-target'];

  const [isRooms, setIsRooms] = useState(false);

  // useEffect(() => {
  //   if (isAuthenticated && mondayData.length) {
  //     setDetails(mondayData, currHour);
  //   }
  // }, [mondayData, currHour, isAuthenticated]);

  // const myRef = useRef(null);
  // const executeScroll = () => scrollToRef(myRef);

  // useEffect(() => {
  //   if (location?.state && location?.state.isRoomsOpen) {
  //     executeScroll();
  //   }
  // }, []);

  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    if ((location?.state && location.state?.isRoomsOpen) || isRooms) {
      executeScroll();
    }
  }, [isRooms]);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  return (
    <>
      <div className={s.container}>
        <TopMenu></TopMenu>
        <div className={s['sections-container']}>
          <div className={`${s.section} ${s['home']}`}>
            <span id='home' className={navTargetClass}></span>
            <HomeSection setIsRooms={setIsRooms} />
          </div>

          <div className={`${s['section']} ${s['rooms']}`} ref={myRef}>
            <span id='rooms' className={navTargetClass}></span>
            {isRooms && <RoomsSection setIsRooms={setIsRooms} />}
          </div>

          <div className={`${s['section']} ${s['info']}`}>
            <span id='info' className={navTargetClass}></span>
            {(view === 'sponsor_r1' || view === 'sponsor_r2') && (
              <InfoSection />
            )}
          </div>

          <div className={`${s['section']} ${s['about']} `}>
            <span id='about' className={navTargetClass}></span>
            <AboutSection />
          </div>

          <div className={`${s['section']} ${s['agenda']} `}>
            <span id='agenda' className={navTargetClass}></span>
            <AgendaSection />
          </div>

          <div className={`${s['section']} ${s['speakers']} `}>
            <span id='speakers' className={navTargetClass}></span>
            <SpeakersSection />
          </div>

          {<div className={`${s['section']} ${s['auction']} `}>
            <span id='auction' className={navTargetClass}></span>
            <AuctionSection />
            </div>}

            {<div className={`${s["section"]} ${s["oursponsors"]} `}>
              <span id="oursponsors" className={navTargetClass}></span>
              <SponsorsSection />
            </div>}

          {/*<div className={`${s['section']} ${s['support']} `}>
            <span id='support' className={navTargetClass}></span>
            <SupportSection />
            </div>*/}

          <div className={`${s['section']} ${s['register']} `}>
            <span id='support' className={navTargetClass}></span>
            <RegisterSection />
          </div>

          <footer>
            <FooterMenu />
          </footer>
        </div>
      </div>
      <div className='help'>Need Help?</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  view: state.interactions.view,
  userData: state.user.userData,
  currDay: state.dashboard.currDay,
  currHour: state.dashboard.currHour,
  mondayData: state.dashboard.mondayData,
  isAuthenticated: state.user.isAuthenticated,
  isRooms: state.interactions.isRooms,
});

export default connect(mapStateToProps, {
  getVhhpUser,
  getAuthStatus,
  getAgendas,
  setDay,
  setDetails,
})(DuringHome);
