import createProfileImg from './img/Create-Profile-Graphic.jpg';
import setUpAgendaImg from './img/Set-Up-Agenda-Graphic.jpg';
import donationImg from './img/Make-a-Donation.png';
import inviteImg from './img/Invitation-Graphic.jpg';
import vhhpUserService, { VhhpUserType } from '../../VhhpUserService';


export type CurrentRegistrationStep = "termsConditions" | number;
export type FlowType = "GeneralGuest" | "InvitedGuest" | "Sponsor";
export interface RegistrationStep {
    label: string;
    img?: string;
    url: string;
    canSkip: boolean;
}

export interface RegistrationFlow {
    type: FlowType;
    currentStep: CurrentRegistrationStep;
    flow: RegistrationStep[];
}


export const GGFlow: RegistrationStep[] = [
    { label: "Create Profile", img: createProfileImg, url: "/register/createprofile", canSkip: false },
    { label: "Set Up Agenda", img: setUpAgendaImg, url: "/register/reviewagenda", canSkip: true },
    { label: "Make a Donation", img: donationImg, url: "/register/makeadonation", canSkip: false }
];

export const IGFlow: RegistrationStep[] = [
    { label: "Create Profile", img: createProfileImg, url: "/register/createprofile", canSkip: false },
    { label: "Set Up Agenda", img: setUpAgendaImg, url: "/register/setupagenda", canSkip: true },
    { label: "Make a Donation", img: donationImg, url: "/register/makeadonation", canSkip: false }
];

export const SFlow: RegistrationStep[] = [
    { label: "Create Profile", img: createProfileImg, url: "/register/createprofile", canSkip: false },
    { label: "Invite Guests", img: inviteImg, url: "/register/inviteguests", canSkip: true },
    { label: "Set Up Agenda", img: setUpAgendaImg, url: "/register/setupagenda", canSkip: true },
];

export const getRegistrationFlow = async () => {
    const vhhpUser = await vhhpUserService.getVhhpUser();
    const userType = vhhpUser?.vhhpUserType || VhhpUserType.GeneralPublic;
    let rFlow: RegistrationFlow;
    switch(userType){
        case VhhpUserType.GeneralPublic: rFlow = { type: "GeneralGuest", flow: GGFlow, currentStep: -1 }; break;
        case VhhpUserType.InvitedGuest: rFlow = { type: "InvitedGuest", flow: IGFlow, currentStep: -1 }; break;
        case VhhpUserType.Sponsor: rFlow = { type: "Sponsor", flow: SFlow, currentStep: -1 }; break;
    }

    const pathName = window.location.pathname.toLowerCase();
    if (pathName === "/register/termsconditions") {
        rFlow.currentStep = "termsConditions";
        return rFlow;
    }
    const idx = rFlow.flow.findIndex(r => r.url === pathName);
    if(idx === -1)
        return undefined;
    rFlow.currentStep = idx;
    return rFlow;
}
