import React from 'react';
import jsonData from '../../../Menus/SponsorLogos.json';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import SponsorMenu from '../../../Menus/SponsorMenu';
import '../../../Menus/SponsorMenu.scss';

const SponsorsSectionRight = ({ sponsorType }) => {
  let logos = jsonData.map((elem, idx) => (
    <a
      key={`sponsor-logo-${idx}`}
      href={elem.url}
      target='_blank'
      className='sponsors-right-content__logos'
    >
      <img src={`/images/sponsors_carousel/${elem.image}`} alt='' />
    </a>
  ));

  // let i = 0;
  // let items = [];

  // while (i < logos.length) {
  //   if (logos.length - i >= 4) {
  //     let slicelogos = logos.slice(i, i + 4);

  //     items.push(
  //       <div className='sponsors-right-content__logo-wrapper'>
  //         {slicelogos.map((elem) => (
  //           <div className='sponsors-right-content__logo'>{elem}</div>
  //         ))}
  //       </div>
  //     );

  //     console.log(slicelogos);
  //   } else {
  //     let remaininglogos = logos.slice(i, logos.length);

  //     items.push(
  //       <div className='sponsors-right-content__logo-wrapper'>
  //         {remaininglogos.map((elem, i) => (
  //           <div key={i} className='sponsors-right-content__logo'>
  //             {elem}
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   }
  //   i += 4;
  // }

  const arrays = [
    <div className='sponsors-right-content__logo-wrapper1'>
      <div className='sponsors-right-content__logo'>{logos[0]}</div>
      <div className='sponsors-right-content__logo'>{logos[1]}</div>
      <div className='sponsors-right-content__logo'>{logos[2]}</div>
      <div className='sponsors-right-content__logo'>{logos[3]}</div>
      <div className='sponsors-right-content__logo'>{logos[4]}</div>
      <div className='sponsors-right-content__logo'>{logos[5]}</div>

    </div>,
      <div className='sponsors-right-content__logo-wrapper2'>
      <div className='sponsors-right-content__logo'>{logos[6]}</div>
      <div className='sponsors-right-content__logo'>{logos[7]}</div>
      <div className='sponsors-right-content__logo'>{logos[8]}</div>
      <div className='sponsors-right-content__logo'>{logos[9]}</div>
      <div className='sponsors-right-content__logo'>{logos[10]}</div>
      <div className='sponsors-right-content__logo'>{logos[11]}</div>

    </div>,
    <div className='sponsors-right-content__logo-wrapper3'>
      <div className='sponsors-right-content__logo'>{logos[12]}</div>
      <div className='sponsors-right-content__logo'>{logos[13]}</div>
      <div className='sponsors-right-content__logo'>{logos[14]}</div>
      <div className='sponsors-right-content__logo'>{logos[15]}</div>
      <div className='sponsors-right-content__logo'>{logos[16]}</div>
      <div className='sponsors-right-content__logo'>{logos[17]}</div>
    </div>,
    <div className='sponsors-right-content__logo-wrapper4'>

    <div className='sponsors-right-content__logo'>{logos[18]}</div>
    <div className='sponsors-right-content__logo'>{logos[19]}</div>
    <div className='sponsors-right-content__logo'>{logos[20]}</div>
    <div className='sponsors-right-content__logo'>{logos[21]}</div>
    <div className='sponsors-right-content__logo'>{logos[22]}</div>
    <div className='sponsors-right-content__logo'>{logos[23]}</div>
    </div>,
    <div className='sponsors-right-content__logo-wrapper5'>

    <div className='sponsors-right-content__logo'>{logos[24]}</div>
    <div className='sponsors-right-content__logo'>{logos[25]}</div>
    <div className='sponsors-right-content__logo'>{logos[26]}</div>
    <div className='sponsors-right-content__logo'>{logos[27]}</div>
    <div className='sponsors-right-content__logo'>{logos[28]}</div>
    <div className='sponsors-right-content__logo'>{logos[29]}</div>
    </div>,
    <div className='sponsors-right-content__logo-wrapper6'>
      <div className='sponsors-right-content__logo'>{logos[30]}</div>
      <div className='sponsors-right-content__logo'>{logos[31]}</div>
      <div className='sponsors-right-content__logo'>{logos[32]}</div>
      <div className='sponsors-right-content__logo'>{logos[33]}</div>
      <div className='sponsors-right-content__logo'>{logos[34]}</div>
      <div className='sponsors-right-content__logo'>{logos[35]}</div>
    </div>,
    <div className='sponsors-right-content__logo-wrapper7'>
      <div className='sponsors-right-content__logo'>{logos[36]}</div>
      <div className='sponsors-right-content__logo'>{logos[37]}</div>
    </div>,
  ];

  const responsive = {
    0: {
      items: 1,
    },
  };

  return (
    <div className='sponsors-right-container'>
    <div className="sponsor-top-left-arrorw"></div>
      <div className='sponsors-right-content'>
        <AliceCarousel
          items={arrays}
          responsive={responsive}
          autoPlayInterval={3000}
          autoPlayDirection='ltr'
          autoPlay={true}
          mouseTrackingEnabled={true}
        />
      </div>
    <div className="sponsor-bottom-right-arrorw"></div>
    </div>
  );
};

export default SponsorsSectionRight;
