import React from 'react';

import headShot0 from './img/speakers/Headshot-0.png';
import headShot1 from './img/speakers/Headshot-1.png';
import headShot2 from './img/speakers/Headshot-2.png';
import headShot3 from './img/speakers/Headshot-3.png';
import headShot4 from './img/speakers/Headshot-4.jpg';
import headShot5 from './img/speakers/Headshot-5.png';
import headShot6 from './img/speakers/Headshot-6.png';
import headShot7 from './img/speakers/Headshot-7.jpg';
import headShot8 from './img/speakers/Headshot-8.jpg';
import headShot9 from './img/speakers/Headshot-9.jpg';

import vector0 from './img/speakers/Vector0.svg';
import vector1 from './img/speakers/Vector1.svg';
import vector2 from './img/speakers/Vector2.svg';
import vector3 from './img/speakers/Vector3.svg';
import vector4 from './img/speakers/Vector4.svg';
import vector5 from './img/speakers/Vector5.svg';
import vector6 from './img/speakers/Vector6.svg';
import vector7 from './img/speakers/Vector7.svg';
import vector8 from './img/speakers/Vector8.svg';
import vector9 from './img/speakers/Vector9.svg';

import thumb0 from './img/speakers/Thumb-0.png';
import thumb1 from './img/speakers/Thumb-1.png';
import thumb2 from './img/speakers/Thumb-2.png';
import thumb3 from './img/speakers/Thumb-3.png';
import thumb4 from './img/speakers/Thumb-4.png';
import thumb5 from './img/speakers/Thumb-5.png';
import thumb6 from './img/speakers/Thumb-6.png';
import thumb7 from './img/speakers/Thumb-7.png';
import thumb8 from './img/speakers/Thumb-8.png';
import thumb9 from './img/speakers/Thumb-9.png';

const bio0: JSX.Element = <>
Karen Haycox is an experienced leader in the affordable housing space and joined Habitat for Humanity New York City and Westchester as CEO in August of 2015. During her tenure at Habitat NYC and Westchester, Karen has instituted a bold new vision for the organization that includes the launch of the Habitat NYC Community Fund, the organization’s emerging CDFI; a founding partnership in the Interboro Community Land Trust, NYC’s first city wide land trust; and a development pipeline that has Habitat well on its way to serving 2021 families by the year 2021, more than tripling the families served throughout its history. The diverse cross section of projects includes Sydney House, a 56-unit co-op underway in the north Bronx, which will be Habitat for Humanity’s largest single-structure, multi-family development ever built anywhere in the world.
<br/><br/>
Previous to Habitat NYC and Westchester, Karen served as Vice President, Fund Development at Southwest Solutions in Detroit and also held a number of senior positions with Habitat for Humanity International in Atlanta including leadership roles on the organization’s annual Carter Work Projects and on international and domestic disaster relief efforts.
<br/><br/>
Karen is deeply involved in New York’s LGBTQ and housing communities, and serves on the boards of the Housing Partnership, which assists in the development, promotion, and revitalization of New York City’s affordable homeownership and rental housing through an assortment of specialized programs, and Rainbow Railroad, which helps LGBTQ people escape state-sponsored violence.
</>;

const bio1: JSX.Element = <>
Ricardo Sebastián (they/them) began volunteering with Habitat for Humanity as a sophomore in college
at Robert Morris University. They launched a campus-wide initiative for students to travel from Chicago
to New Orleans and help rebuild after Hurricane Katrina. They then became the Events Chair of the
Chicagoland chapter of Habitat Young Professionals (HYP), where they helped raise over $4.5 million in
conjunction with Habitat Chicagoland. This journey led them to volunteer with the Habitat Young
Professionals Advisory Council (HYPAC), where they mentored HYP chapters and Habitat affiliates across
the country. HYPAC led them to New York City, where their experience with event production and deep
commitment to Habitat’s mission lead Habitat NYC and Westchester to hire Ricardo to produce the
organization&#39;s annual gala. Ricardo officially joined the staff in 2020 and now oversees all volunteer
initiatives and strategic initiatives, as well as the Habitat House Party.
</>;
const bio2: JSX.Element = <>
George Latimer, a third generation Westchester County native, was born on November 22, 1953 in Mount
Vernon to the late Stanley and Loretta (Miner) Latimer. In January 2018, Latimer was elected as the ninth
Westchester County Executive. His sound business experience, record of public service and dedication to
Westchester County in both the public and private sector, have been the driving force behind his efforts
for the past 30 years. Latimer has honored his pledge to stand by the citizens he represents. From City
Councilman, to County Legislator, to Assemblyman, to State Senator and now County Executive, Latimer
takes pride in fighting for what Westchester residents need.
<br/><br/>
Latimer, a resident of the City of Rye for over 30 years, began his career in public service on the Rye City
Council. For thirteen years, Latimer served as a Westchester County Legislator representing District 7,
then as Minority Leader, and was later elected the first Democratic Chairman of the Board of Legislators
in Westchester County history.
<br/><br/>
Latimer oversaw the passage of ground-breaking legislation, such as the creation of the Human Rights
Commission, Smoke Free Workplace and Waste Haulers Laws. Latimer is always regarded as bi-partisan
and transparent, appointing his Republican colleagues to chair various committees. He facilitated a fair
redistricting plan that included the input of members of the chamber’s minority party, and outside “good
government” groups. Latimer also established the first cable TV coverage of the Board of Legislators
meetings.
<br/><br/>
Elected in 2004 to the State Assembly, Latimer established a solid record of accomplishment, sponsoring
legislation and advancing funding for environmental matters and a myriad of other issues. Latimer was
elected to the State Senate in November of 2012, representing the 37th District.
<br/><br/>
After attending Mount Vernon public schools, Latimer graduated with a Bachelor’s Degree from Fordham
University in the Bronx, as well as a Master’s Degree in Public Administration from New York University’s
Wagner School. Upon completing his education, Latimer served as a marketing executive, gaining first-
hand business and economic experience at a number of major corporate subsidiaries of Nestle and ITT.
Using inclusion and openness as a foreground, Latimer is fighting to make Westchester a destination for
all people to live, work and enjoy. Learn more about Westchester County by visiting <a href="https://www.westchestergov.com" >www.westchestergov.com</a>
</>;
const bio3 = <>
    Habitat NYC and Westchester Board of Directors Chair, Doug Morse, and Habitat House Party Co-Chair,
Claudia Morse, have supported our affordable housing mission for more than a decade. In addition to
their impact on affordable housing, the Morse Family Foundation also supports the arts, particularly
museums and music education, environmental conservation, and health and human services.

</>;
const bio4 = <>
    A Native New Yorker, Duane Wright has invested over 15 years in the banking/mortgage industry (8 years with M&T) helping customers achieve their dream of home ownership. Recipient of both M&T Presidents’ Club & Chairman Club Awards for the past four years and has been recognized by the State of New York Mortgage Agency as one of the top originators in the state.
</>;
const bio5 = <>
Council Member Margaret Chin represents District 1 in Lower Manhattan. She is
Chair of the Council’s Committee on Aging and is a member of the Committees on
Consumer Affairs and Business Licensing; Housing and Buildings; Rules, Privileges and
Elections; Standards and Ethics and Youth Services, as well as a member of the
Women&#39;s Caucus, Progressive Caucus, and Black, Latino, and Asian Caucus (BLAC).
Margaret immigrated to the U.S. with her family from Hong Kong when she was nine
years old. She grew up in NYC Chinatown and attended P.S. 130 and JHS 65. She
graduated from the Bronx High School of Science and from the City College of New
York with a degree in education. Prior to her historic victory in 2010 making her the first
Asian woman elected to City Council, she worked at Asian Americans for Equality
(AAFE), an organization that she helped to form when she was in college. In the decade
she served at AAFE, she fought for affordable housing and equal opportunities for
immigrant families, and successfully advocated for bilingual ballots for the Asian
community.

</>;
const bio6 = <>
Kendall Hooper is ready to be out on his own.
<br/><br/>
Kendall, a Brooklyn native who loves baseball and motivational speaking, and works for a non-profit organization, has lived with his mother in a NYCHA apartment his entire life. The family works hard to get by, but it can be paycheck to paycheck. They aren’t too crowded, but sometimes there’s loud, irritating noise, it can be hard to focus, and Kendall doesn’t always feel safe in the apartment. Kendall is ready for his own place. As he says, owning a home will “break generational curses.” 
<br/><br/>
Kendall had never thought about buying a home before, Until he heard from a friend Jaron M., a Habitat NYC Homeowner in the Dean Street Condominiums, suggested that he apply for  the Home Purchase Program. Here was the chance to get a place of his own. Happily, he was accepted into the program and is now on his way to becoming a Habitat Homeowner too!
<br/><br/>
He’s most excited about “not have to stress about the everyday struggles of not having access to affordable housing.”
<br/><br/>
Kendall is all about community. He has attended community events and volunteered in his current community, and he decided to apply for the Sydney House co-op because he wanted to “contribute to the community.” Finally, he can join the community he’s always wanted to – a community of homeowners.
</>;
const bio7 = <>
Educator, visionary leader, sought after speaker, and consummate change agent, LaKeesha Walrond is a force pushing women and girls toward the infinite possibilities that await their arrival. She has dedicated her life to empowering women and children to live the best life possible. Hailing from the great state of Texas, she understands the importance of going big or going home.  As a child, she endured molestation, abuse, and patriarchy; which led her to be passionate about providing empowering resources for children and women. In an effort to reach the hearts of women in an undisturbed atmosphere, Dr. Walrond launched the Getting to Greatness Women’s Conference in 2013.  G2G holds conferences, retreats, workshops and panel discussions promoting women’s empowerment, leadership development, spiritual wellness and mental and psychological wholeness. Reaching thousands of women nationwide, the G2G conference has ben held in New York, Florida, South Carolina, Canada, the Bahamas and   Arizona. In 2017, she will launch the first book of a six part ‘Let’s Talk About It’  series. This series is focused on empowering children  with the tools they need to stay safe. Her ‘Let’s Talk About It’ children’s books series is expected to bring attention to necessary conversations about child safety that must be held between children and the adults that love them. 

<br/><br/>
 Dr. Walrond earned her undergraduate degree from Spelman College, a Master of Divinity from Union Theological Seminary, and a Master of Arts in Teaching, Master in School Administration, and Doctor of Philosophy from the University of North Carolina at Chapel Hill. She has worked in both public and charter schools as well as the United Church of Christ and Baptist Churches.  She is a dynamic speaker who has addressed both national and international audiences; having spoken in South Africa, Canada, The Bahamas, South Korea and India. She is the proud mother of Jasmyn and Michael, III, and currently serves as the Executive Pastor at First Corinthian Baptist Church along side her husband. Michael A. Walrond Jr., the senior Pastor.  She hopes to improve the lives of children and empower the lives of women to the benefit of all humanity
 </>;
const bio8 = <>
Ariana J. is making her parents proud. 
<br/><br/>
She’s the daughter of two Jamaican immigrants who, as she told us, “Sacrificed so much to give my brother and I a chance at a successful life.” Not only does Ariana have a great career at DonorsChoose, a crowdfunding non-profit for teachers, but soon, she will be a Habitat Homeowner. “My homeownership is truly [my parents’] dream come true,” Ariana said. 
<br/><br/>
Ariana has lived with her family in their home her whole life, and while she promises she will miss them, she’s excited for the adventure of living on her own! She’s an avid cook (lasagna is the most recently mastered recipe), swimmer, runner and dancer. “All so I can keep eating,” she told us with a smile. 
<br/><br/>
Ariana looked into buying a home in the Bronx, but was having trouble finding something that fit her budget. “It was extremely stressful. I couldn't find anything in my price range and the few places I could scrape enough together to afford were in terrible condition,” Ariana said. “The process to get approved for a mortgage was depressing and time consuming and no real estate agents would take me seriously. I was close to giving up, then came Habitat.”
<br/><br/>
Ariana’s mom Andrea saw an article about Sydney House, a 56-unit co-op in the north Bronx, and sent along the application. “It was too good of an opportunity to pass up! It was the best chance I would have at homeownership. I promised myself I wouldn't get my hopes up, but not applying would be doing myself a disservice.” After some careful paperwork and an interview, Ariana was accepted into the Habitat NYC Home Purchase Program!
<br/><br/>
So what inspired someone so young to become a Homeowner? It all comes back to her family. “I'm most excited about making my family proud. Their faces when I move in are going to be priceless.”
<br/><br/>
But to Ariana, it’s more than that. To her, homeownership is a way to build up her hometown. “Homeownership, in my opinion, is also the core way to build strong and safe communities. I'll be able to actively contribute to making my community of the Bronx a better place, forming close relationships with my neighbors and working closely together to build up our neighborhood.”

The first thing she plans to do in her new apartment is “Write a letter to myself and hide it in the back of a drawer or closet. When I find that letter in 5-10 years, I'll be able to reflect back on my first day in my new apartment and relive the immense joy and gratitude.”
<br/><br/>
We hope Ariana is proud of herself too. 

</>;


const bio9 = <>
    Karen Haycox is an experienced leader in the affordable housing space who joined Habitat for Humanity New York City as CEO in August of 2015. During her tenure at Habitat NYC, Karen has instituted a bold new vision for the organization, with a goal of increasing the number of families served through construction and preservation from 550 to 2,021 by the year 2021. She has also overseen the groundbreaking of Sydney House, a 56-unit co-op in the north Bronx, which will be Habitat for Humanity’s largest single-structure, multi-family development ever built.
    <br/><br/>
    Previous to Habitat NYC, Karen served as Vice President, Fund Development at Southwest Solutions and held a number of senior positions with Habitat for Humanity International in Atlanta. At Habitat for Humanity International, Karen served in leadership roles on the organization’s annual Carter Work Projects and on international and domestic disaster relief efforts, including the 2004 Indian Ocean tsunami, Hurricanes Katrina &amp; Rita, the 2010 Haiti earthquake, Midwestern tornados in Texas, Missouri and Oklahoma as well as Superstorm Sandy.
    <br/><br/>
    Karen is deeply involved in New York’s LGBTQ+ and housing communities. She has served on the boards of Stonewall Community Development Corporation, an organization dedicated to providing New York’s LGBTQ seniors with supportive, affordable and safe housing options, and Rainbow Railroad, an international organization helping LGBTQ+ individuals around the world to escape state-sponsored violence, and find safety and freedom from persecution.
    <br/><br/>
    She also serves on the boards of the New York City-based Housing Partnership, which assists in the development, promotion, and revitalization of New York City’s affordable homeownership and rental housing and, the New York State CDFI Coalition providing advocacy and resources for New York State Community Development Financial Institutions.
    <br/><br/>
    Karen received her degree in Creative Cinematography, Film &amp; Television from Humber College in Toronto, Canada, and enjoyed a successful career as a producer in advertising, film and communications before she transitioned to the affordable housing sector.
    <br/><br/>
    She lives in the New York City area with her dog Ripley and cat Pepper.
</>;



export interface SpeakerImageConfig {
    key: number;
    classNamePrefix: string;
    name: string;
    title: string;
    type: string;
    headshotSrc: string;
    vectorSrc: string;
    thumbSrc: string;
    bio?:JSX.Element;
}

const SpeakerImageConfig: SpeakerImageConfig[] = [
    { key: 0, classNamePrefix: 'sp0-', name: 'Karen Haycox', title: 'CEO, Habitat NYC and Westchester', type: 'Guest Speaker', headshotSrc: headShot0, vectorSrc: vector0, thumbSrc: thumb0, bio: bio0 },
    { key: 1, classNamePrefix: 'sp1-', name: 'Ricardo Sebastian', title: 'Senior Director, Strategic Initiatives, Habitat NYC and Westchester ', type: 'Guest Speaker', headshotSrc: headShot1, vectorSrc: vector1, thumbSrc: thumb1, bio: bio1 },
    { key: 2, classNamePrefix: 'sp2-', name: 'George Latimer', title: 'Westchester County Executive ', type: 'Guest Speaker', headshotSrc: headShot2, vectorSrc: vector2, thumbSrc: thumb2, bio: bio2 },
    { key: 3, classNamePrefix: 'sp3-', name: 'Doug & Claudia Morse', title: 'Principal, North Lake Capital, LLC ', type: 'Moderator ', headshotSrc: headShot3, vectorSrc: vector3, thumbSrc: thumb3, bio: bio3 },
    { key: 4, classNamePrefix: 'sp4-', name: 'Duane Wright', title: 'Banking Officer, M&T Bank', type: 'Guest Speaker', headshotSrc: headShot4, vectorSrc: vector4, thumbSrc: thumb4, bio: bio4 },
    { key: 5, classNamePrefix: 'sp5-', name: 'Margaret S. Chin', title: 'Council Member for the 1st District of the New York City Council', type: 'Guest Speaker', headshotSrc: headShot5, vectorSrc: vector5, thumbSrc: thumb5, bio: bio5 },

    { key: 6, classNamePrefix: 'sp6-', name: 'Kendall Hooper', title: 'Home buyer', type: 'Home buyer', headshotSrc: headShot6, vectorSrc: vector6, thumbSrc: thumb6, bio: bio6 },
    { key: 7, classNamePrefix: 'sp7-', name: 'The Rev. LaKeesha Walrond, Ph.D.', title: 'President, New York Theological Seminary', type: 'Guest Speaker', headshotSrc: headShot7, vectorSrc: vector7, thumbSrc: thumb7, bio: bio7 },

    { key: 8, classNamePrefix: 'sp8-', name: 'Ariana Jacobs – Sydney', title: 'Homeowner', type: 'Homeowner', headshotSrc: headShot8, vectorSrc: vector8, thumbSrc: thumb8, bio: bio8 },
    // { key: 9, classNamePrefix: 'sp9-', name: 'Karen Haycox', title: 'CEO, Habitat for Humanity New York City', type: 'CEO Speech', headshotSrc: headShot9, vectorSrc: vector9, thumbSrc: thumb9, bio: bio9 }

];

export default function SpeakerImagesConfg() {
    return SpeakerImageConfig;
}
