import React, { useState } from 'react'

const Dropdown = ({ placeholder, options, roomType, onChange }) => {

    const [open, setOpen] = useState(false);

    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e, selectedValue) => {

        if (e.target.checked) {
            onChange(selectedValue);
            setOpen(false);


        } else {
            onChange('');
            setOpen(false);

        }
    };

    return (

        <div className={`dropdown ${open ? 'active' : ''}`}>
            <div className='dropdown__toggler flex' onClick={(e) => setOpen(!open)}>
                <p className='body3'>{roomType || placeholder}</p>
                <div className='dropdown__toggler-image'>
                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.8807 6.83347L7.36173 0.145776C7.17093 -0.0480709 6.85292 -0.0480709 6.66212 0.145776L0.143101 6.83347C-0.0477002 7.02731 -0.0477002 7.35039 0.143101 7.54424L0.842703 8.25501C1.0335 8.44885 1.3515 8.44885 1.54231 8.25501L6.66212 2.98885C6.85292 2.79501 7.17093 2.79501 7.36173 2.98885L12.4815 8.2227C12.6723 8.41654 12.9903 8.41654 13.1811 8.2227L13.8807 7.51193C14.0398 7.31808 14.0398 7.02731 13.8807 6.83347Z" fill="#3B3B3B" />
                    </svg>
                </div>
            </div>

            {open && (
                <ul className='dropdown__menu scroll'>
                    <li className='dropdown__placeholder body3'>Select a Field</li>
                    {options.map((opt) => (
                        <li
                            className='dropdown__menu-item body3'

                        >
                            <label className="checkbox-container"><p>{opt}</p>
                                {roomType === opt ? (

                                    <input type="checkbox" checked={true} onClick={(e) => handleChange(e, opt)} />
                                ) : (
                                        <input type="checkbox" onClick={(e) => handleChange(e, opt)} />

                                    )

                                }
                                <span className="checkmark"></span>
                            </label>

                        </li>
                    ))}
                </ul>
            )}
        </div>

    );


}

export default Dropdown
